import { createAsyncThunk } from "@reduxjs/toolkit";
import {} from "@constants";
import {} from "utils";
import { AppStateType } from "reducers";
import {
  CreateSandboxParams,
  FetchAvailableDumpFilesParams,
  DeleteSandboxesParams,
  FetchSandboxParams,
  CreateDumpFileParams,
  generateRealmIdParams,
} from "SandboxModule/types";
import * as SandboxServices from "SandboxModule/services";
import omit from "lodash/omit";

export const createSandbox = createAsyncThunk(
  "createSandbox",
  async (
    params: CreateSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onFailure, onSuccess } = params;

    const {
      authReducer: { user },
    } = getState() as AppStateType;

    const auth_token = user?.auth_token;
    try {
      const data = omit(params, "onSuccess");
      const response = await SandboxServices.createSandbox(
        params,
        data,
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const deleteSandboxes = createAsyncThunk(
  "deleteSandboxes",
  async (
    params: DeleteSandboxesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const { realmIds, onSuccess, onFailure } = params;
    try {
      const promises = realmIds.map((id) =>
        SandboxServices.deleteSandbox(params, { realmId: id }, {}, auth_token)
      );
      const response = await Promise.allSettled(promises);

      onSuccess && onSuccess({ response });
      return response;
    } catch (e) {
      onFailure && onFailure({ error: e });
      return rejectWithValue(e);
    }
  }
);

const stubSandboxes = {
  sandbox_info: [
    {
      realm_id: "mosaic_dump",
    },
    {
      realm_id: "mosaic_2",
    },
  ],
};

export const fetchSandboxes = createAsyncThunk(
  "fetchSandboxes",
  async (
    params: FetchSandboxParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;

    try {
      const response = await SandboxServices.fetchSandboxes(
        params,
        {},
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export const fetchAvailableDumpFiles = createAsyncThunk(
  "fetchAvailableDumpFiles",
  async (
    params: FetchAvailableDumpFilesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.getAvailableDumpFiles(
        {},
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createDumpFile = createAsyncThunk(
  "createDumpFile",
  async (
    { onSuccess, fileName, onFailure }: CreateDumpFileParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;

    try {
      const data = {
        fileName,
      };
      const response = await SandboxServices.createDumpFile(
        {},
        data,
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response.data;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

export const generateRealmId = createAsyncThunk(
  "generateRealmId",
  async (
    { onFailure, onSuccess }: generateRealmIdParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await SandboxServices.generateRealmId(
        {},
        {},
        auth_token
      );

      onSuccess && onSuccess({ response });
      return response;
    } catch (error) {
      onFailure && onFailure({ error });
      return rejectWithValue(error);
    }
  }
);

// Probably need to put in another module though
// export const replaceMembers = createAsyncThunk(
//   "replaceMembers",
//   async (params, { dispatch, getState, rejectWithValue }) => {
//     try {
//       const {
//         authReducer: { user },
//       } = getState() as AppStateType;
//       const auth_token = user?.auth_token;
//       const response = {};
//       return response;
//     } catch (e) {
//       return rejectWithValue(e);
//     }
//   }
// );
