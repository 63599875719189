import SimpleMenu from "components/SimpleMenu";
import { useState } from "react";
import { SimpleMenuItem } from "types";

type HookProps = {
  renderAnchorEl?: () => JSX.Element;
};

const useSimpleMenu = ({ renderAnchorEl }: HookProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderSimpleMenu = ({
    disabled,
    items,
  }: {
    disabled: boolean;
    items: SimpleMenuItem[];
  }) => {
    return (
      <SimpleMenu
        disabled={disabled}
        items={items}
        anchorEl={anchorEl}
        handleClick={handleClick}
        handleClose={handleClose}
        renderAnchorEl={renderAnchorEl}
      />
    );
  };

  return {
    anchorEl,
    handleClose,
    handleClick,
    renderSimpleMenu,
  };
};

export default useSimpleMenu;
