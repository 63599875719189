import { JSXElementConstructor, ReactElement } from "react";

import { Modal as MuiModal, Backdrop, Fade, Box } from "@material-ui/core";

const BoxContainerStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "4px solid #fff",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

type ModalProps = {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  open: boolean;
  onClose: any;
  modalWidth?: number;
};

const Modal = (props: ModalProps) => {
  const { children, open, onClose, modalWidth = 400 } = props;

  return (
    <MuiModal
      closeAfterTransition
      BackdropComponent={Backdrop}
      open={open}
      onClose={onClose}
    >
      <Fade in={open}>
        <Box
          style={{ wordBreak: "break-word" }}
          sx={{
            ...BoxContainerStyle,
            width: modalWidth,
          }}
        >
          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
