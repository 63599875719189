import {
  getAPI,
  getApiIntegrationServer,
  postApiRailsServer,
  postApiIntegrationServer,
  postApiGalaxyServer,
  getApiGalaxyServer,
  putApiGalaxyServer,
  deleteApiGalaxyServer,
} from "services";
import omit from "lodash/omit";
import { FetchSandboxParams, CreateSandboxParams } from "SandboxModule/types";

/* --------------------------- Samples --------------------------- */
export const fetchSandboxes = (
  params: FetchSandboxParams,
  config = {},
  token: string
) => getApiGalaxyServer(`/sandbox/all`, config, token);

export const createSandbox = (
  params: CreateSandboxParams,
  data: Omit<CreateSandboxParams, "onSuccess">,
  config = {},
  token?: string
) => postApiGalaxyServer(`/sandbox/createSandbox`, data, config, token);

export const deleteSandbox = (
  params = {},
  data: { realmId: string },
  config = {},
  token?: string
) => postApiGalaxyServer(`/sandbox/destroySandbox`, data, config, token);

export const getAvailableDumpFiles = (
  params = {},
  data = {},
  config = {},
  token?: string
) => postApiGalaxyServer(`/sandbox/getAvailableDumpFiles`, data, config, token);

export const createDumpFile = (
  params = {},
  data = {},
  config = {},
  token?: string
) => postApiGalaxyServer(`/database/createDumpFile`, data, config, token);

export const generateRealmId = (params = {}, config = {}, token?: string) =>
  getApiGalaxyServer(`/sandbox/generateRealmId`, config, token);
