import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createCustomTheme from "./theme";
import Routes from "./routes/Routes";
import { AppStateType } from "reducers";
import AuthenticationLayer from "containers/AuthenticationLayer";
import SecurityLayer from "containers/SecurityLayer";
import useAppOnMount from "hooks/useAppOnMount";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

const AppMountWrapper: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  useAppOnMount();

  return children;
};

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);

  return (
    <React.Fragment>
      <AuthenticationLayer>
        <SecurityLayer>
          <HelmetProvider>
            <Helmet
              titleTemplate="%s | Mosaic Galaxy"
              defaultTitle="Mosaic Galaxy - Integration Monitoring"
            />
            <AppMountWrapper>
              <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiThemeProvider
                    theme={createCustomTheme(theme.currentTheme)}
                  >
                    <ThemeProvider
                      theme={createCustomTheme(theme.currentTheme)}
                    >
                      <Routes />
                    </ThemeProvider>
                  </MuiThemeProvider>
                </MuiPickersUtilsProvider>
              </StylesProvider>
            </AppMountWrapper>
          </HelmetProvider>
        </SecurityLayer>
      </AuthenticationLayer>
    </React.Fragment>
  );
}

export default App;
