import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

type EllipsisTextProps = {
  text: string;
  width: string;
  style?: {};
  className?: string;
};

const TextContainer = styled.div<{
  width: EllipsisTextProps["width"];
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  width: ${({width}) => width || 'unset'}; 
`;

const EllipsisText: React.FC<EllipsisTextProps> = ({
  text,
  width,
  style = {},
  className = "",
  children,
}) => {
  return (
    <Tooltip title={text || ''} placement="top">
      <TextContainer
        width={width}
        style={style}
        className={className}
      >
        {children}
      </TextContainer>
    </Tooltip>
  );
};

export default EllipsisText;
