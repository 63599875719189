import { INTERVAL } from "@constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as IntegrationService from "IntegrationModule/services";
import * as Types from "IntegrationModule/types";
import * as constants from "@constants";
import sortBy from "lodash/sortBy";
import { AppStateType } from "reducers";
import { setFilters } from "actionCreators";

export const fetchTeamIntegrationList = createAsyncThunk(
  "fetchTeamIntegrationList",
  async (
    { teamID, onSuccess }: Types.fetchTeamIntegrationListParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getTeamIntegrationList(
        teamID,
        {},
        auth_token
      );
      onSuccess &&
        onSuccess.forEach(({ action, selector }) =>
          action(selector({ response }))
        );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchMappingStatusCounts = createAsyncThunk(
  "fetchMappingStatusCounts",
  async (
    { targetServiceId, days }: Types.fetchMappingStatusCountsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getMappingStatusCounts(
        targetServiceId,
        days,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchDataOverviewPerDataTypes = createAsyncThunk(
  "fetchDataOverviewPerDataTypes",
  async (
    { targetServiceIds }: Types.fetchDataOverviewPerDataTypesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = { targetServiceIds };
      const response = await IntegrationService.postDataOverviewPerDataTypes(
        body,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchDataHistoryPerDataTypes = createAsyncThunk(
  "fetchDataHistoryPerDataTypes",
  async (
    {
      teamId,
      interval,
      limit,
      offset,
      isInitialFetch,
    }: Types.fetchDataHistoryPerDataTypesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const APIOptions = {
      limit,
      offset,
    };

    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      let payloadData;
      if (isInitialFetch) {
        const responses = await Promise.all(
          Object.values(INTERVAL).map((interval) =>
            IntegrationService.getDataHistoryPerDataTypeByTeamID(
              teamId,
              {
                ...APIOptions,
                interval,
              },
              {},
              auth_token
            )
          )
        );
        const datas = responses.map((response) =>
          sortBy(response.data, ["resInterval"])
        );
        payloadData = Object.values(INTERVAL).reduce(
          (acc: any, date: string, index) => {
            const dateObj = {
              [date]: {
                data: [...datas[index]],
                offset: 0,
                hasReachTheEnd: false,
              },
            };
            acc = {
              ...acc,
              ...dateObj,
            };
            return acc;
          },
          {}
        );
      } else {
        const response = await IntegrationService.getDataHistoryPerDataTypeByTeamID(
          teamId,
          {
            ...APIOptions,
            interval: interval as string,
          },
          {},
          auth_token
        );
        payloadData = {
          [interval as string]: {
            offset,
            data: [...sortBy(response.data, ["resInterval"])],
          },
        };
      }
      return {
        ...payloadData,
        isInitialFetch,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchPendingEntities = createAsyncThunk(
  "fetchPendingEntities",
  async (
    {
      targetServiceId,
      mappingStatuses,
      dataTypes,
      day,
    }: Types.fetchPendingEntitiesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const body = {
        targetServiceId,
        mappingStatuses,
        dataTypes,
        startDate: day,
      };
      const response = await IntegrationService.getPendingEntities(
        body,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationServerQueuesStatus = createAsyncThunk(
  "fetchIntegrationServerQueuesStatus",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getIntegrationServerQueuesStatus(
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchApiRequestLog = createAsyncThunk(
  "fetchApiRequestLog",
  async (
    params: Types.fetchApiRequesLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState();
      const {
        authReducer: { user },
      } = state as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getApiRequestLog(
        params,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchNetworkLog = createAsyncThunk(
  "fetchNetworkLog",
  async (
    params: Types.fetchNetworkLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getNetworkLog(
        params,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------- Error log ------------------------------- */

export const fetchTeamNetworkErrorLog = createAsyncThunk(
  "fetchTeamNetworkErrorLog",
  async (
    params: Types.fetchTeamNetworkErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const body = {
      targetService: params.targetService,
      mosaicTeamIds: params.mosaicTeamIds,
      startDate: params.startDate,
      limit: params.limit,
      offset: params.offset,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getNetworkErrorLog(
        body,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchTeamAgentErrorLog = createAsyncThunk(
  "fetchTeamAgentErrorLog",
  async (
    {
      agentOnly,
      targetService,
      targetServiceId,
      offset,
      limit,
      startDate,
      isInitialFetch,
    }: Types.fetchTeamAgentErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const body = {
      agentOnly: agentOnly || targetService === "quickbooks-desktop",
      targetService,
      targetServiceId,
      limit,
      offset,
      startDate,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.postRecentErrors(
        body,
        {},
        auth_token
      );
      return response.data.response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchSingleEntity = createAsyncThunk(
  "fetchSingleEntity",
  async (
    params: Types.fetchSingleEntityParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getSingleEntity(
        params,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchLastTimeSync = createAsyncThunk(
  "fetchLastTimeSync",
  async (
    { targetServiceIds, targetService }: Types.fetchLastTimeSyncParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.postTelemetryLastUpdates(
        {
          targetServiceIds,
          targetService,
        },
        {},
        auth_token
      );
      return response.data.response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationSettings = createAsyncThunk(
  "fetchIntegrationSettings",
  async (
    params: Types.fetchIntegrationsSettingsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { onSuccess } = params;
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getIntegrationSettings(
        params,
        {},
        auth_token
      );
      onSuccess && onSuccess({ response });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchCsvUploads = createAsyncThunk(
  "fetchCsvUploads",
  async (
    params: Types.fetchCsvUploadsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getCsvUploads(
        params,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateIntegrationNotes = createAsyncThunk(
  "updateIntegrationNotes",
  async (
    params: Types.updateIntegrationNotesParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const { onDone } = params;
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.updateIntegrationNotes(
        params,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    } finally {
      onDone && onDone();
    }
  }
);
