import { createAsyncThunk } from "@reduxjs/toolkit";
import * as GlobalService from "GlobalModule/services";
import * as Types from "GlobalModule/types";
import { AppStateType } from "reducers";

export const createTeam = createAsyncThunk(
  "createTeam",
  async (
    params: Types.createTeamParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await GlobalService.createTeam(
        params,
        auth_token as string
      );
      return response.data;
    } catch (e) {
      return e;
    }
  }
);

// Unused
export const fetchRecurlyPlan = createAsyncThunk(
  "fetchRecurlyPlan",
  async (
    params: Types.fetchRecurlyPlanParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await GlobalService.getRecurlyPlan(auth_token as string);
      return response.data.plans;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
