import { createReducer } from "@reduxjs/toolkit";
import { ValueOf } from "type-fest";
import groupBy from "lodash/groupBy";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import * as constants from "@constants";
import {
  IntegrationServiceMetricsDataPerServiceInterface,
  IntegrationServiceMetricsDataInterface,
  IntegrationServiceMetricsTotalDataInterface,
  RecentErrorsType,
  IntegrationServiceMetricsTotalDataInstance,
  ActiveIntegrations,
  IntegrationHealth,
} from "types";
import { serializeId } from "utils";

export type InitialStateType = {
  service_metrics: IntegrationServiceMetricsDataInterface;
  service_metrics_sum: IntegrationServiceMetricsTotalDataInstance[];
  agent_error_log: RecentErrorsType[];
  network_error_log: Record<any, any>[];
  active_integrations: Array<ActiveIntegrations>;
  integration_health: {
    [isoStateId: string]: any;
    // Record<
    //   ValueOf<typeof constants.INTEGRATION_SERVICE_NAMES>,
    //   IntegrationHealth[]
    // >;
  };

  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

const initialState: InitialStateType = {
  service_metrics: {
    limit: 0,
    targetServiceAndTeamIds: [],
    data: [],
    targetService: "",
  },
  service_metrics_sum: [],
  agent_error_log: [],
  network_error_log: [],
  active_integrations: [],
  integration_health: {},

  requestStatusesAndErrors: {
    // Example with boolean
    [thunkActions.fetchTeamIntegrationList.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  builder.addCase(actions.setGeneralIntegrationState, (state, action) => {
    state = {
      ...state,
      ...action.payload,
    };
  });

  /* --------------------------------Service metrics------------------------------------------ */
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      const { targetService, limit } = arg;

      const nextState = {
        targetService,
        limit,
        data: payload.results,
        targetServiceAndTeamIds: payload.teams,
      };
      state.service_metrics = nextState;
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetrics.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetrics.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.service_metrics = {
        targetService: "",
        limit: 0,
        targetServiceAndTeamIds: [],
        data: [],
      };
    }
  );

  /* --------------------------------Service metrics sum (total counts)------------------------------------------ */
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.service_metrics_sum = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationServiceMetricsTotal.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchIntegrationServiceMetricsTotal.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.service_metrics_sum = [];
    }
  );

  /* ------------------------------- Active Integration (IS) ------------------------------ */
  builder.addCase(
    thunkActions.fetchActiveIntegrations.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchActiveIntegrations.fulfilled,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.active_integrations = action.payload;
    }
  );
  builder.addCase(
    thunkActions.fetchActiveIntegrations.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchActiveIntegrations.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.active_integrations = [];
    }
  );

  /* ---------------------------- Network Error Log --------------------------- */
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.network_error_log = isInitialFetch
        ? payload
        : [...state.network_error_log, ...payload];
    }
  );
  builder.addCase(
    thunkActions.fetchNetworkErrorLog.rejected,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchNetworkErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: action.error,
      };
      state.network_error_log = [];
    }
  );

  /* ------------------------------- Agent Error Log ------------------------------ */
  builder.addCase(thunkActions.fetchAgentErrorLog.pending, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchAgentErrorLog.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.fetchAgentErrorLog.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isInitialFetch } = arg;
      state.requestStatusesAndErrors[
        thunkActions.fetchAgentErrorLog.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.agent_error_log = isInitialFetch
        ? payload
        : [...state.agent_error_log, ...payload];
    }
  );
  builder.addCase(thunkActions.fetchAgentErrorLog.rejected, (state, action) => {
    state.requestStatusesAndErrors[
      thunkActions.fetchAgentErrorLog.typePrefix
    ] = {
      isRequesting: false,
      error: action.error,
    };
    state.agent_error_log = [];
  });
  /* --------------------------- Integration Health --------------------------- */
  builder.addCase(
    thunkActions.fetchIntegrationHealth.pending,
    (state, action) => {
      const { meta } = action;
      const { arg } = meta;
      const { modifier, isoStateId } = arg;

      if (isoStateId && modifier) {
        modifier.handlePending &&
          modifier.handlePending({
            state,
            action,
            isoStateId,
            payload: null,
          });
      }
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationHealth.fulfilled,
    (state, action) => {
      const {
        meta: { arg },
        payload,
      } = action;
      const { isoStateId, modifier } = arg;
      if (isoStateId) {
        if (modifier) {
          modifier.handleFulfilled &&
            modifier.handleFulfilled({
              payload,
              action,
              state,
              isoStateId,
            });
        }
      }
    }
  );
  builder.addCase(
    thunkActions.fetchIntegrationHealth.rejected,
    (state, action) => {
      const {
        meta: { arg },
      } = action;
      const { isoStateId } = arg;
      state.requestStatusesAndErrors[
        serializeId([
          thunkActions.fetchIntegrationHealth.typePrefix,
          isoStateId as string,
        ])
      ] = {
        isRequesting: false,
        error: action.error,
      };
    }
  );
});
