import React, { useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { exchangeTokenThunk as exchangeToken, fetchMe } from "thunk";
import { AppStateType } from "reducers";
import { signOut, resetAuth } from "actionCreators";
import { redirectToLoginScreen } from "utils";
import { LinearQuery } from "components/Progress";

const TokenExchangeLayer = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: AppStateType) => state.authReducer.user);
  const authErrors = useSelector(
    (state: AppStateType) => state.authReducer.error
  );

  const clearCacheAndRedirect = useCallback(async () => {
    await dispatch(signOut());
    redirectToLoginScreen();
  }, [dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (searchParams.has("access_token") && !user?.auth_token) {
      const tempToken = searchParams.get("access_token");
      tempToken && dispatch(exchangeToken(tempToken));
    }
    /** Do not include searchParams in dependency array.
     * We only want this useEffect to run
     * once, and only when auth token is not defined
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.auth_token]);

  useEffect(() => {
    if (user?.auth_token) {
      dispatch(fetchMe(user.auth_token));
    }
  }, [dispatch, user?.auth_token]);

  if (authErrors.AUTHENTICATION_ERROR) {
    return (
      <div>
        <span>There is an issue.</span>
        <button onClick={clearCacheAndRedirect}>Go back to login screen</button>
      </div>
    );
  }

  /** A check to see if user is logged in
   * on other route other than /access_token
   */
  if (!searchParams.has("access_token") && !user?.auth_token) {
    setTimeout(() => {
      clearCacheAndRedirect();
    }, 1000);

    return <div>Not authenticated. Redirecting to login screen...</div>;
  }

  /** Auth token and `me` is fetched
   * Move onto security layer for super admin check
   */
  if (user?.auth_token && user?.account) {
    return <div>{children}</div>;
  }
  return (
    <div>
      <span>Loading data...</span>
      <LinearQuery />
    </div>
  );
};

export default TokenExchangeLayer;
