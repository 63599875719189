import { useEffect } from "react";
import { useMount } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import { fetchSandboxes, fetchAvailableDumpFiles } from "SandboxModule/thunks";
import ApiClientManager from "core/apiClient";
import { fetchActiveIntegrations, fetchIntegrationHealth } from "thunk";
import {
  AVAILABLE_INTEGRATION_SERVICES,
  integrationHealthIsoStateIds,
} from "@constants";
import { formatIntegrationHealthDateParams } from "utils";
import moment from "moment";
import { integrationHealthIsoStateModifiers } from "IntegrationModule/utils";

const useAppOnMount = () => {
  const dispatch = useDispatch();

  useMount(() => {
    if (ApiClientManager.getIsSandboxEnabled()) {
      dispatch(fetchSandboxes({}));
      dispatch(fetchAvailableDumpFiles({}));
    }
    // fetch active counts and integraiton health
    const targetServiceToFetch = Array.from(AVAILABLE_INTEGRATION_SERVICES);
    targetServiceToFetch.forEach((targetService) => {
      dispatch(
        fetchIntegrationHealth({
          targetService,
          serviceNames: [targetService],
          startDate: formatIntegrationHealthDateParams(moment()),
          endDate: formatIntegrationHealthDateParams(moment()),
          isoStateId: integrationHealthIsoStateIds.IntegrationCard,
          modifier:
            integrationHealthIsoStateModifiers[
              integrationHealthIsoStateIds.IntegrationCard
            ],
        })
      );
    });
    dispatch(fetchActiveIntegrations());
  });

  // const authUser = useSelector(selectAuthUser);
  // useEffect(() => {
  //   // fetch all plan
  //   if (authUser?.auth_token && authUser?.account) {
  //     dispatch(fetchRecurlyPlan({}));
  //   }
  // }, [dispatch, authUser]);
};

export default useAppOnMount;
