import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectAuthToken } from "selectors";

import { createNewTeam } from "services";
import { makeObjectWithGalaxyProps } from "utils";
import useAppNavigation from "./useAppNavigation";
import useAppLocation from "hooks/useAppLocation";

export type SignupFieldsProps = {
  email: string;
  company: string;
  skip_credit_card_info: boolean;
  trial_duration: number;
};

export type initialPlusSettingButtonType = {
  showAllAddOptions: boolean;
  showCreateTeamsForm: boolean;
  showCreateSandboxForm: boolean;
  createNewTeamErrorMessage: string;
  createNewTeamSuccessMessage: string;
  showMessage: boolean;
  isLoading: boolean;
  signupFields: SignupFieldsProps;
};

export type plusSettingButtonProps = {
  handleOptionClick: (fromType: string) => void;
  handleInitialOpen: () => void;
  handleClose: () => void;
  showAllAddOptions: boolean;
  showCreateTeamsForm: boolean;
  showCreateSandboxForm: boolean;
  createNewTeamErrorMessage: string;
  createNewTeamSuccessMessage: string;
  showMessage: boolean;
  isLoading: boolean;
  signupFields: SignupFieldsProps;
};

const INITIAL_FIELDS_VALUES = {
  email: "",
  company: "",
  skip_credit_card_info: false,
  trial_duration: 15,
  sales_force_id: "",
  sales_force_link: "",
};

export const INITIAL_PLUS_SETTINGS_BUTTON_STATE: initialPlusSettingButtonType = {
  showAllAddOptions: false,
  showCreateTeamsForm: false,
  showCreateSandboxForm: false,
  createNewTeamErrorMessage: "",
  createNewTeamSuccessMessage: "",
  showMessage: false,
  isLoading: false,
  signupFields: INITIAL_FIELDS_VALUES,
};

export default function usePlusSettingsButton() {
  const history = useHistory();
  const authToken = useSelector(selectAuthToken);
  const { navigateToTeamProfile } = useAppNavigation();
  const { isOnSandboxPage } = useAppLocation();

  const [
    plusSettingButtonState,
    setplusSettingButtonState,
  ] = useState<initialPlusSettingButtonType>(
    INITIAL_PLUS_SETTINGS_BUTTON_STATE
  );

  const handleInitialOpen = () => {
    setplusSettingButtonState({
      ...INITIAL_PLUS_SETTINGS_BUTTON_STATE,
      showAllAddOptions: false,
      showCreateTeamsForm: !isOnSandboxPage,
      showCreateSandboxForm: isOnSandboxPage,
    });
  };

  const handleClose = () =>
    setplusSettingButtonState({
      ...INITIAL_PLUS_SETTINGS_BUTTON_STATE,
    });

  const closeAlert = () =>
    setplusSettingButtonState({
      ...plusSettingButtonState,
      showMessage: false,
    });

  const setLoading = (isLoading: boolean) => {
    setplusSettingButtonState({
      ...plusSettingButtonState,
      isLoading,
    });
  };

  const handleFieldsChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    const { name, value, type, checked } = event.target;
    setplusSettingButtonState({
      ...plusSettingButtonState,
      signupFields: {
        ...plusSettingButtonState.signupFields,
        [name]: type === "checkbox" ? checked : value,
      } as any, // Bad workaround
    });
  };
  const handleSubmitCreateTeam = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const { signupFields } = plusSettingButtonState;
    event.preventDefault();

    setLoading(true);
    const credentials = makeObjectWithGalaxyProps(signupFields);

    try {
      const response = await createNewTeam(
        credentials,
        {},
        authToken as string
      );
      const { team_created, team_id } = response.data;
      if (team_created) {
        setplusSettingButtonState({
          ...plusSettingButtonState,
          createNewTeamSuccessMessage: `Successfully registered with email: ${credentials.email}. Redirecting to team profile...`,
          createNewTeamErrorMessage: "",
          showMessage: true,
          isLoading: false,
        });
        setTimeout(() => {
          handleClose();
          const navigate = navigateToTeamProfile(team_id);
          navigate();
        }, 1000);
      } else {
        setplusSettingButtonState({
          ...plusSettingButtonState,
          createNewTeamSuccessMessage: "",
          createNewTeamErrorMessage: `Creating team with email ${credentials.email} failed. This team was already created. Please try again.`,
          showMessage: true,
          isLoading: false,
        });
      }
    } catch (e) {
      const status = e?.response.status;

      const errorMessage =
        status === 409
          ? "This email record already existed. Try a new email"
          : `There is something wrong. Please try again later.${
              status && "Error code " + status
            }`;

      setplusSettingButtonState({
        ...plusSettingButtonState,
        createNewTeamSuccessMessage: "",
        createNewTeamErrorMessage: errorMessage,
        showMessage: true,
        isLoading: false,
      });
    }
  };

  const handleOptionClick = (fromType: string) => {
    setplusSettingButtonState({
      ...plusSettingButtonState,
      showAllAddOptions: false,
      [fromType]: true,
    });
  };

  return {
    ...plusSettingButtonState,
    setplusSettingButtonState,
    handleClose,
    handleInitialOpen,
    handleSubmitCreateTeam,
    closeAlert,
    handleFieldsChange,
    handleOptionClick,
  };
}
