import { INTERVAL, intervalsHash } from "@constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as IntegrationService from "IntegrationModule/services";
import * as ProfileService from "ProfileModule/services";
import * as IntegrationTypes from "IntegrationModule/types";
import {
  generateDateBeforeToday,
  makeIntegrationTeamInfoMappings,
} from "utils";
import { AppStateType } from "reducers";
import { updateTeamsInfoMapping } from "ReportsModule/actionCreators";

export const fetchActiveIntegrations = createAsyncThunk(
  "fetchActiveIntegrations",
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getActiveIntegrations(
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationServiceMetrics = createAsyncThunk(
  "fetchIntegrationServiceMetrics",
  async (
    {
      targetService,
      offset,
      limit,
      interval,
      isInitialFetch,
      onSuccess,
    }: IntegrationTypes.fetchIntegrationServiceMetricsParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const date = generateDateBeforeToday(
      INTERVAL.DAY,
      intervalsHash[interval].value
    );
    const queryParams = {
      targetService,
      offset,
      limit,
      date,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;

      const response = await IntegrationService.getIntegrationServiceMetrics(
        queryParams,
        {},
        auth_token
      );
      const {
        teams = [],
      }: IntegrationTypes.ServiceMetricsAPIResponseData = response.data;

      onSuccess.forEach(({ action, selector }) =>
        action(selector({ response, targetService }))
      );

      const initialAccumlatorState: {
        mosaicTeamIds: Array<number>;
        targetServiceIds: Array<number>;
      } = {
        mosaicTeamIds: [],
        targetServiceIds: [],
      };
      const { mosaicTeamIds } = teams.reduce((acc, curr) => {
        const {
          mosaicTeamId,
          targetServiceId,
        }: IntegrationTypes.ServiceMetricsAPITeamsField = curr;
        acc.mosaicTeamIds.push(mosaicTeamId);
        acc.targetServiceIds.push(targetServiceId);
        return acc;
      }, initialAccumlatorState);

      if (mosaicTeamIds.length > 0) {
        const config = {
          params: {
            ids: mosaicTeamIds,
            limit: mosaicTeamIds.length,
          },
        };
        const teamsForCreatingNewMappingResults = await ProfileService.getTeamByID(
          config,
          user?.auth_token
        );
        dispatch(
          updateTeamsInfoMapping(
            makeIntegrationTeamInfoMappings(
              teamsForCreatingNewMappingResults.data.teams,
              targetService
            )
          )
        );
      }
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchIntegrationServiceMetricsTotal = createAsyncThunk(
  "fetchIntegrationServiceMetricsTotal",
  async (
    {
      interval,
      targetService,
    }: IntegrationTypes.fetchIntegrationServiceMetricsTotalParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const subtractBy = intervalsHash[interval].value;
    const date = generateDateBeforeToday(INTERVAL.DAY, subtractBy); // because we are subtracting strictly by 7, 14, 30 DAYS

    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getIntegrationServiceMetricsTotal(
        targetService,
        date,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* -------------------------------- Error log ------------------------------- */

export const fetchNetworkErrorLog = createAsyncThunk(
  "fetchNetworkErrorLog",
  async (
    params: IntegrationTypes.fetchNetworkErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const body = {
      targetService: params.targetService,
      mosaicTeamIds: params.mosaicTeamIds,
      startDate: params.startDate,
      limit: params.limit,
      offset: params.offset,
    };
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const response = await IntegrationService.getNetworkErrorLog(
        body,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const fetchAgentErrorLog = createAsyncThunk(
  "fetchAgentErrorLog",
  async (
    {
      agentOnly,
      targetService,
      offset,
      limit,
      startDate,
      isInitialFetch,
    }: IntegrationTypes.fetchAgentErrorLogParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      authReducer: { user },
    } = getState() as AppStateType;
    const auth_token = user?.auth_token;
    const body = {
      agentOnly: agentOnly || targetService === "quickbooks-desktop",
      targetServiceId: "all",
      targetService,
      limit,
      offset,
      startDate,
    };
    try {
      const response = await IntegrationService.postRecentErrors(
        body,
        {},
        auth_token
      );
      return response.data.response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

/* --------------------------- Integration Health --------------------------- */

const stub = [
  {
    id: 2,
    targetServiceId: 114,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 7, //
    errors: {},
  },
  {
    id: 2.1,
    targetServiceId: 114,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 11,
    successCount: 7, //
    errors: {},
  },
  {
    id: 2.2,
    targetServiceId: 114,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 5,
    successCount: 5, //
    errors: {},
  },
  {
    id: 2.3,
    targetServiceId: 114,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 14,
    successCount: 14, //
    errors: {},
  },
  {
    id: 4,
    targetServiceId: 190,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 10,
    errors: {},
  },
  {
    id: 1,
    targetServiceId: 218,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 10,
    errors: {},
  },
  {
    id: 2,
    targetServiceId: 257,
    date: "2022-03-01T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 8,
    errors: {},
  },
  {
    id: 3,
    targetServiceId: 271,
    date: "2022-03-01T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 5,
    errors: {},
  },
  {
    id: 4,
    targetServiceId: 432,
    date: "2022-03-02T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 10,
    errors: {},
  },
  {
    id: 5,
    targetServiceId: 277,
    date: "2022-03-01T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 8,
    errors: {},
  },
  {
    id: 6,
    targetServiceId: 257,
    date: "2022-03-01T08:00:00.000Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 5,
    errors: {},
  },
  {
    id: 7,
    targetServiceId: 533,
    date: "2022-03-02T08:00:00.006Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 10,
    errors: {},
  },
  {
    id: 8,
    targetServiceId: 539,
    date: "2022-03-01T08:00:00.005Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 8,
    errors: {},
  },
  {
    id: 9,
    targetServiceId: 854,
    date: "2022-03-01T08:00:00.004Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 5,
    errors: {},
  },
  {
    id: 10,
    targetServiceId: 432,
    date: "2022-03-02T08:00:00.003Z",
    serviceName: "quickbooks-online",
    requestCount: 10,
    successCount: 10,
    errors: {},
  },
];

export const fetchIntegrationHealth = createAsyncThunk(
  "fetchIntegrationHealth",
  async (
    params: IntegrationTypes.fetchIntegrationHealthParams,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        authReducer: { user },
      } = getState() as AppStateType;
      const auth_token = user?.auth_token;
      const paramsToUse = {
        startDate: params.startDate,
        endDate: params.endDate,
        targetServiceIds: params.targetServiceIds,
        serviceNames: params.serviceNames,
        targetService: params.targetService,
      };

      const response = await IntegrationService.getIntegrationHealth(
        paramsToUse,
        {},
        auth_token
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
