import * as Types from "types";
import { Moment } from "moment";
import { ValueOf } from "type-fest";
import { INTEGRATION_SERVICE_NAMES, twoWaySyncIntegrations } from "@constants";

export const isIntegrationHealthy = (
  integrationHealth: Types.IntegrationHealth
) =>
  integrationHealth
    ? integrationHealth.requestCount === integrationHealth.successCount
    : null;

export const formatIntegrationHealthDateParams = (momentDate: Moment) =>
  momentDate.format("YYYY-MM-DD");

export const sumRequestAndSuccessIntegrationHealthCountsByTargetService = (
  integrationHealthDataByTargetServiceHash: Record<
    string,
    Types.IntegrationHealth[]
  >,
  targetService: string
) => {
  let requestTotal = 0;
  let successTotal = 0;

  const integrationHealthDataByTargetService =
    integrationHealthDataByTargetServiceHash[targetService] || [];

  integrationHealthDataByTargetService.forEach(
    (data: Types.IntegrationHealth) => {
      const { requestCount, successCount } = data;
      requestTotal += requestCount;
      successTotal += successCount;
    }
  );

  return {
    requestTotal,
    successTotal,
    successToRequestRatioLabel: `${successTotal} / ${requestTotal}`,
    successToRequestRatioPercent: requestTotal
      ? +((successTotal / requestTotal) * 100).toFixed(1)
      : null,
  };
};

export const checkIsTwoWaySyncIntegration = (
  targetService: ValueOf<typeof INTEGRATION_SERVICE_NAMES>
) => {
  return twoWaySyncIntegrations.has(targetService);
};
