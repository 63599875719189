import { useCallback } from "react";
import useSimpleMenu from "./useSimpleMenu";
import { Button } from "@material-ui/core";
import { SimpleMenuItem } from "types";

const useBulkActionsSelector = ({
  selectedItemsIds,
  menuItems,
  anchorElProps,
}: {
  selectedItemsIds: (number | string)[]; // Must be unique
  menuItems: SimpleMenuItem[];
  anchorElProps?: {
    isDisabled?: boolean;
    name?: string;

    renderCustomAnchorEl?: ({
      isDisabled,
      name,
    }: {
      isDisabled?: boolean;
      name?: string;
    }) => JSX.Element;
  };
}) => {
  const { isDisabled, name, renderCustomAnchorEl } = anchorElProps || {};

  const renderAnchorEl = () => {
    return renderCustomAnchorEl ? (
      renderCustomAnchorEl({ isDisabled })
    ) : (
      <Button variant="outlined" color="primary" disabled={isDisabled}>
        {name || "Modify"} {selectedItemsIds.length || ""}
      </Button>
    );
  };

  const { renderSimpleMenu } = useSimpleMenu({
    renderAnchorEl,
  });

  const BulkActionsSelector = renderSimpleMenu({
    disabled: isDisabled ?? false,
    items: menuItems,
  });

  return {
    BulkActionsSelector,
  };
};

export default useBulkActionsSelector;
