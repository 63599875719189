import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import { dashboardLayoutRoutes } from ".";

import DashboardLayout from "layouts/Dashboard";
import AuthLayout from "layouts/Auth";
import Page404 from "../pages/auth/Page404";
import { RouteType } from "types/routes";
import { getReportTeamsPageDeepLinkUrl } from "utils";
import { REPORTS_TABLE_TEAMS_PATH_DEEP_LINK } from "@constants";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
  routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element, index: number) => {
          const Guard = element.guard || React.Fragment;
          const ElementComponent = element.component || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <Guard>
                    <ElementComponent {...props} />
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const Routes = () => (
  <Router>
    {" "}
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes as any)}
      {/* <Route
        exact
        path={REPORTS_TABLE_TEAMS_PATH_DEEP_LINK}
        render={(params) => {
          console.info("MATCH?", params);

          return <Redirect to={getReportTeamsPageDeepLinkUrl({})} />;
        }}
      /> */}
      {/* {childRoutes(AuthLayout, authLayoutRoutes)} */}{" "}
      <Redirect exact path="/" to={getReportTeamsPageDeepLinkUrl({})} />
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
