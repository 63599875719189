import { createReducer } from "@reduxjs/toolkit";
import { ValueOf } from "type-fest";
import groupBy from "lodash/groupBy";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import * as constants from "@constants";
import { serializeId } from "utils";
import { Sandbox } from "types";
import ApiClientManager from "core/apiClient";

export type InitialStateType = {
  railsSandboxes: Sandbox[];
  selectedRailsSandbox: Sandbox;
  dumpFiles: string[];
  requestStatusesAndErrors: {
    [x: string]: {
      // can be serialized
      isRequesting: boolean;
      error: Record<string, any>;
    };
  };
};

export const initialState: InitialStateType = {
  requestStatusesAndErrors: {},
  dumpFiles: [],
  railsSandboxes: [],
  selectedRailsSandbox: ApiClientManager.getRailsSandbox(),
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  //   builder.addCase(actions.setGeneralIntegrationState, (state, action) => {
  //     state = {
  //       ...state,
  //       ...action.payload,
  //     };
  //   });
  builder.addCase(actions.setSelectedRailsSandbox, (state, { payload }) => {
    // state.selectedRailsSandbox = payload.sandbox; - if want the input to change immediately on click, use this
    ApiClientManager.setRailsSandbox(payload.sandbox);
  });
  /* --------------------------------Async Request------------------------------------------ */
  builder.addCase(thunkActions.fetchSandboxes.pending, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.fetchSandboxes.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    // Note that fetchSandboxes are fetching RAILS sandbox
    thunkActions.fetchSandboxes.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[thunkActions.fetchSandboxes.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      state.railsSandboxes = payload.sandbox_info;
    }
  );
  builder.addCase(thunkActions.fetchSandboxes.rejected, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.fetchSandboxes.typePrefix] = {
      isRequesting: false,
      error: action.error,
    };
  });
  builder.addCase(thunkActions.deleteSandboxes.pending, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.deleteSandboxes.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });
  builder.addCase(
    thunkActions.deleteSandboxes.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.deleteSandboxes.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
    }
  );
  builder.addCase(thunkActions.deleteSandboxes.rejected, (state, action) => {
    state.requestStatusesAndErrors[thunkActions.deleteSandboxes.typePrefix] = {
      isRequesting: false,
      error: action.error,
    };
  });
  /* -------------------------------------------------------------------------- */
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.pending,
    (state, action) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.fulfilled,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      const { arg } = meta;
      state.dumpFiles = payload.files;
    }
  );
  builder.addCase(
    thunkActions.fetchAvailableDumpFiles.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAvailableDumpFiles.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
    }
  );
});
