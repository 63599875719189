import {
  getAPI,
  getApiIntegrationServer,
  getApiRailsServer,
  postApiIntegrationServer,
  putApiIntegrationSever,
} from "services";
import { RecentErrorsBodyType, TelemetryLastUpdatesBodyType } from "types";
import {
  fetchPendingEntitiesParams,
  fetchNetworkLogParams,
  fetchTeamNetworkErrorLogParams,
  fetchIntegrationsSettingsParams,
  fetchIntegrationHealthByServiceNameParams,
  fetchIntegrationHealthParams,
  fetchCsvUploadsParams,
  updateIntegrationNotesParams,
} from "IntegrationModule/types";
import omit from "lodash/omit";

/**
 *
 * WARNING: Calling Integration server API will not work if you don't have CORS disabled
 * For development, try using this add-on
 * https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf/related?hl=en
 */

export const getTeamIntegrationList = (
  mosaicTeamID: number,
  config: Record<string, any> = {},
  token: string = ""
) => getApiIntegrationServer(`/telemetry/team/${mosaicTeamID}`, config, token);

export const getMappingStatusCounts = (
  targetServiceID: number,
  days: number,
  config: Record<string, any> = {},
  token: string = ""
) =>
  getApiIntegrationServer(
    `/telemetry/mappingStats/${targetServiceID}/${days}`,
    config,
    token
  );

export const getDataHistoryPerDataTypeByTeamID = (
  mosaicTeamID: number,
  options: {
    interval: string;
    limit?: number;
    offset: number;
  } = {
    interval: "day",
    limit: 12,
    offset: 0,
  },
  config: Record<string, any> = {},
  token: string = ""
) =>
  getApiIntegrationServer(
    `/telemetry/dataHistory/${mosaicTeamID}/${options.interval}/${options.limit}/${options.offset}`,
    config,
    token
  );

export const getIntegrationServiceMetrics = (
  {
    targetService,
    offset,
    limit,
    date,
  }: {
    targetService: string;
    offset: number;
    limit: number;
    date: string;
  },
  config: Record<string, any> = {},
  token: string = ""
) =>
  getApiIntegrationServer(
    `/telemetry/serviceMetrics/${targetService}/${limit}/${offset}/${date}`,
    config,
    token
  );

export const getIntegrationServiceMetricsTotal = (
  targetService: string,
  startDate: string, // In format: (MM.DD.YYYY) - 01.01.2021 (UTC)
  config: Record<string, any> = {},
  token: string = ""
) =>
  getApiIntegrationServer(
    `/telemetry/integrationServiceMetrics/${targetService}/${startDate}`,
    config,
    token
  );

/**
 * A post request that retrieves body overview for all targetServicesIDs passed in the array
 * @param body
 *  @key targetServiceIds
 *  @type Array<number>
 * @param config
 * @returns
 */
export const postDataOverviewPerDataTypes = (
  body: {
    targetServiceIds: Array<number>;
  },
  config: Record<string, any> = {},
  token: string = ""
) => postApiIntegrationServer("/telemetry/dataOverview", body, config, token);

/**
 * A post request that retrieves most recent errors for a specific integration target service
 * @param body
 *  @key
 */

export const postRecentErrors = (
  body: RecentErrorsBodyType,
  config: Record<string, any> = {},
  token?: string
) => postApiIntegrationServer("/telemetry/recentErrors", body, config, token);

/**
 * A post request that retrieves most recent errors for all target service ids
 * The only
 * @param body
 *  @key
 */

export const postGeneralRecentErrors = (
  body: RecentErrorsBodyType,
  config: Record<string, any> = {},
  token?: string
) => postApiIntegrationServer("/telemetry/recentErrors", body, config, token);

/**
 * A post request that retrieves latest update from IS for various body types
 *
 */

export const postTelemetryLastUpdates = (
  body: TelemetryLastUpdatesBodyType,
  config: Record<string, any> = {},
  token?: string
) => postApiIntegrationServer("/telemetry/lastUpdates", body, config, token);

/**
 * A GET request that retrieves integration server's queues status
 */

export const getIntegrationServerQueuesStatus = (
  config: Record<string, any> = {},
  token: string = ""
) => getApiIntegrationServer("/telemetry/queueStatus", config, token);

export const getExceededRetries = (
  targetServiceId: number,
  config = {},
  token?: string
) =>
  getApiIntegrationServer(
    `/telemetry/exceededRetries/${targetServiceId}`,
    config,
    token
  );

export const getPendingEntities = (
  body: Omit<fetchPendingEntitiesParams, "day">,
  config = {},
  token?: string
) =>
  postApiIntegrationServer("/telemetry/pendingEntities", body, config, token);

export const getNetworkLog = (
  params: fetchNetworkLogParams,
  config = {},
  token?: string
) => {
  const { mosaicTeamId, sessionId, startDate } = params;

  return getApiIntegrationServer(
    `/telemetry/findNetworkLog/${mosaicTeamId}/${sessionId}/${startDate}`,
    config,
    token
  );
};

export const getNetworkErrorLog = (
  body: Omit<fetchTeamNetworkErrorLogParams, "isInitialFetch">,
  config = {},
  token?: string
) =>
  postApiIntegrationServer(
    `/telemetry/getNetworkErrorLogs`,
    body,
    config,
    token
  );

type ApiRequestLogParams = {
  apiRequestIds: string[];
  teamId: number;
  dataType: string;
};
export const getSingleEntity = (
  params: { entityId: string },
  config = {},
  token?: string
) =>
  getApiIntegrationServer(
    `/telemetry/findEntity/${params.entityId}`,
    config,
    token
  );

export const getApiRequestLog = (
  params: ApiRequestLogParams,
  config = {},
  token?: string
) => {
  return getApiRailsServer(
    `/integrations/api_request_logs`,
    {
      ...config,
      params: {
        team_id: params.teamId,
        data_type: params.dataType,
        api_request_ids: params.apiRequestIds,
      },
    },
    token
  );
};

export const getActiveIntegrations = (config = {}, token?: string) =>
  getApiIntegrationServer(`/telemetry/integrationCounts`, config, token);

export const getIntegrationSettings = (
  params: fetchIntegrationsSettingsParams,
  config = {},
  token?: string
) =>
  getApiIntegrationServer(
    `/configs/integrations`,
    {
      ...config,
      params: {
        targetServiceId: params.targetServiceId,
      },
    },
    token
  );

/* --------------------------- Integration Health --------------------------- */
export const getIntegrationHealth = (
  params: Omit<fetchIntegrationHealthParams, "isoStateId">,
  config = {},
  token?: string
) =>
  postApiIntegrationServer(
    `/forward/${params.targetService}/integrationHealth/getHealth`,
    omit(params, "targetService"),
    config,
    token
  );

/* --------------------------- Csv Uploads --------------------------- */
export const getCsvUploads = (
  params: fetchCsvUploadsParams,
  config = {},
  token?: string
) =>
  getApiIntegrationServer(
    `/forward/csv-agent/session_info`,
    {
      params,
      ...config,
    },
    token
  );

/* --------------------------- Update Integration Notes --------------------------- */
export const updateIntegrationNotes = (
  params: updateIntegrationNotesParams,
  config = {},
  token?: string
) => {
  const body = {
    notes: params.notes,
  };

  return postApiIntegrationServer(
    `/telemetry/setIntegrationNotes/${params.targetServiceId}`,
    body,
    config,
    token
  );
};
