import { Draft, createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import {
  GenericTeamInterface,
  SelectedTargetServiceType,
  TeamMembershipType,
  DefaultBoard,
} from "types";
import * as ProfileTypes from "ProfileModule/types";

type InitialStateType = {
  team_members: Array<TeamMembershipType>;
  selectedTeam: GenericTeamInterface;
  selectedTargetService: SelectedTargetServiceType;
  agentToken: ProfileTypes.AgentToken;
  // integrationConnect: ProfileTypes.IntegrationConnection;
  provisionIntegrationResponse: ProfileTypes.ProvisionIntegration; // provision
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: any;
      error: any;
      isSuccess?: boolean;
    };
  };
  default_board: DefaultBoard | object;
};

export const initialState: InitialStateType = {
  team_members: [],
  selectedTeam: {},
  default_board: {},
  selectedTargetService: {
    name: "",
    id: "",
    pendingEntitiesDataTypes: [],
    pendingEntitiesMappingStatuses: [],
  },
  agentToken: "",
  // integrationConnection: {}, TO COME
  provisionIntegrationResponse: {},
  requestStatusesAndErrors: {
    // Example
    [thunkActions.fetchTeamMembers.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.updateTeamMember.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.updateTeam.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.fetchTeam.typePrefix]: {
      isRequesting: false,
      error: {},
    },
    [thunkActions.fetchTeamApiToken.typePrefix]: {
      isRequesting: false,
      error: {},
    },
  },
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  builder.addCase(actions.setSelectedTeam, (state, { payload }) => {
    state.selectedTeam = payload;
  });

  builder.addCase(actions.setTargetService, (state, { payload }) => {
    state.selectedTargetService = payload;
  });

  builder.addCase(actions.updateProfileState, (state, { payload }) => {
    const nextState = { ...state };
    Object.keys(payload).forEach((reduxKey) => {
      nextState[reduxKey as keyof typeof nextState] = payload[reduxKey];
    });
    return nextState;
  });

  /* --------------------------- Fetch Team Members --------------------------- */
  builder.addCase(
    thunkActions.fetchTeamMembers.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.fetchTeamMembers.fulfilled,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.team_members = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchTeamMembers.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamMembers.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      state.team_members = [];
    }
  );

  /* --------------------------- Update Team Member --------------------------- */
  builder.addCase(
    thunkActions.updateTeamMember.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.updateTeamMember.fulfilled,
    (state, { payload, meta }) => {
      const { arg } = meta;
      const { teamMembershipId } = arg;
      const updatedMember = payload.team_member?.account;

      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: false,
        error: payload.data?.error,
      };

      state.team_members = state.team_members.map((team_member) =>
        team_member.team_membership_id === teamMembershipId
          ? { ...team_member, ...updatedMember, id: teamMembershipId }
          : team_member
      );
    }
  );
  builder.addCase(
    thunkActions.updateTeamMember.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.updateTeamMember.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
    }
  );

  /* ------------------------------- Fetch Team ------------------------------- */
  builder.addCase(thunkActions.fetchTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.fetchTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });

  builder.addCase(
    thunkActions.fetchTeam.fulfilled,
    (state, { payload, meta }) => {
      const { arg } = meta;
      state.requestStatusesAndErrors[thunkActions.fetchTeam.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      state.selectedTeam = {
        id: arg.teamId,
        ...payload.teams[0],
      };
    }
  );
  builder.addCase(thunkActions.fetchTeam.rejected, (state, { meta, error }) => {
    state.requestStatusesAndErrors[thunkActions.fetchTeam.typePrefix] = {
      isRequesting: false,
      error,
    };
    state.selectedTeam = {};
  });
  /* ------------------------------- Update Team ------------------------------- */
  builder.addCase(thunkActions.updateTeam.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
      isRequesting: true,
      error: {},
    };
  });

  builder.addCase(
    thunkActions.updateTeam.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
        isRequesting: false,
        error: {},
      };
      const nextTeamState = {
        ...state.selectedTeam,
        ...payload,
      };
      state.selectedTeam = nextTeamState;
    }
  );
  builder.addCase(
    thunkActions.updateTeam.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[thunkActions.updateTeam.typePrefix] = {
        isRequesting: false,
        error,
      };
    }
  );
  /* ------------------------------- Fetch team API token ------------------------------- */
  builder.addCase(
    thunkActions.fetchTeamApiToken.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamApiToken.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.fetchTeamApiToken.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamApiToken.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.selectedTeam = {
        ...state.selectedTeam,
        ...payload,
      };
    }
  );
  builder.addCase(
    thunkActions.fetchTeamApiToken.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchTeamApiToken.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
    }
  );
  /* ------------------------------- Reset Members Password ------------------------------- */
  builder.addCase(
    thunkActions.resetMemberPassword.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.resetMemberPassword.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
    }
  );
  /* ------------------------------- Fetch Agent token ------------------------------- */
  builder.addCase(
    thunkActions.fetchAgentToken.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAgentToken.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );

  builder.addCase(
    thunkActions.fetchAgentToken.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAgentToken.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      state.agentToken = payload;
    }
  );
  builder.addCase(
    thunkActions.fetchAgentToken.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.fetchAgentToken.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
      alert("Fail to get agent token, please try again");
    }
  );
  /* ------------------------------- Check Connnection Active TO COME ------------------------------- */
  // builder.addCase(
  //   thunkActions.fetchTeamApiToken.pending,
  //   (state, { payload }) => {
  //     state.requestStatusesAndErrors[
  //       thunkActions.fetchTeamApiToken.typePrefix
  //     ] = {
  //       isRequesting: true,
  //       error: {},
  //     };
  //   }
  // );

  // builder.addCase(
  //   thunkActions.fetchTeamApiToken.fulfilled,
  //   (state, { payload, meta }) => {
  //     state.requestStatusesAndErrors[
  //       thunkActions.fetchTeamApiToken.typePrefix
  //     ] = {
  //       isRequesting: false,
  //       error: {},
  //     };
  //     state.selectedTeam = {
  //       ...state.selectedTeam,
  //       ...payload,
  //     };
  //   }
  // );
  // builder.addCase(
  //   thunkActions.fetchTeamApiToken.rejected,
  //   (state, { meta, error }) => {
  //     state.requestStatusesAndErrors[
  //       thunkActions.fetchTeamApiToken.typePrefix
  //     ] = {
  //       isRequesting: false,
  //       error,
  //     };
  //   }
  // );
  /* ------------------------------- Provision Customer response ------------------------------- */
  builder.addCase(thunkActions.provisionQbd.pending, (state, { payload }) => {
    state.requestStatusesAndErrors[
      thunkActions.provisionIntegration.typePrefix
    ] = {
      isRequesting: true,
      error: {},
    };
  });

  builder.addCase(
    thunkActions.provisionQbd.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: {},
        isSuccess: true,
      };
      state.provisionIntegrationResponse = payload;
    }
  );
  builder.addCase(
    thunkActions.provisionQbd.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: payload,
        isSuccess: false,
      };
      state.provisionIntegrationResponse = {};
    }
  );
  /* ------------------------------- Provision Integration response ------------------------------- */
  builder.addCase(
    thunkActions.provisionIntegration.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };
    }
  );
  builder.addCase(
    thunkActions.provisionIntegration.fulfilled,
    (state, { payload, meta }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: {},
        isSuccess: true,
      };
      state.provisionIntegrationResponse = payload;
    }
  );
  builder.addCase(
    thunkActions.provisionIntegration.rejected,
    (state, { meta, payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.provisionIntegration.typePrefix
      ] = {
        isRequesting: false,
        error: payload,
        isSuccess: false,
      };
      alert("Provision failed, please try again");
      state.provisionIntegrationResponse = {};
    }
  );
  /* ------------------------------- Set single user password ------------------------------- */
  builder.addCase(
    thunkActions.setSingleUserTemporaryPassword.pending,
    (state, { payload }) => {}
  );
  builder.addCase(
    thunkActions.setSingleUserTemporaryPassword.fulfilled,
    (state, { payload, meta }) => {
      const newPassword = meta.arg.password;
      alert(
        `Password has been set successfully. The user new password is ${newPassword}`
      );
    }
  );
  builder.addCase(
    thunkActions.setSingleUserTemporaryPassword.rejected,
    (state, { meta, payload }) => {
      alert("Failed to set password. Please try again.");
    }
  );
  /* ------------------------------- Bulk Activate Accounts ------------------------------- */
  builder.addCase(
    thunkActions.bulkActivateAccounts.pending,
    (state, { payload }) => {}
  );
  builder.addCase(
    thunkActions.bulkActivateAccounts.fulfilled,
    (state, { payload, meta }) => {
      const { activated_account_ids, failed_account_ids } = payload;
      const successfullyActivateIdsText = activated_account_ids?.length
        ? `Accounts with successful activation: ${activated_account_ids.join(
            ", "
          )}`
        : "";
      const failedActivateIdsText = failed_account_ids?.length
        ? `Accounts failed to activate: ${failed_account_ids.join(", ")}`
        : "";
      alert(`${successfullyActivateIdsText}\n${failedActivateIdsText}`);

      const activatedAccountIdsSet = new Set(activated_account_ids);

      if (activatedAccountIdsSet.size) {
        state.team_members = state.team_members.map((member) =>
          activatedAccountIdsSet.has(member.account_id)
            ? { ...member, is_active: true }
            : member
        );
      }
    }
  );
  builder.addCase(
    thunkActions.bulkActivateAccounts.rejected,
    (state, { meta, payload }) => {
      alert("All accounts activation failed. Please try again.");
    }
  );
  /* ------------------------------- Set single user password ------------------------------- */
  builder.addCase(
    thunkActions.getDefaultBoard.pending,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.getDefaultBoard.typePrefix
      ] = {
        isRequesting: true,
        error: {},
      };

      state.default_board = {};
    }
  );

  builder.addCase(
    thunkActions.getDefaultBoard.fulfilled,
    (state, { payload }) => {
      state.requestStatusesAndErrors[
        thunkActions.getDefaultBoard.typePrefix
      ] = {
        isRequesting: false,
        error: {},
      };
      if (Array.isArray(payload)) {
        state.default_board = payload[0];
      } else {
        // Case when response return teams (when prod is not updated)
        state.default_board = {};
      }
    }
  );
  builder.addCase(
    thunkActions.getDefaultBoard.rejected,
    (state, { meta, error }) => {
      state.requestStatusesAndErrors[
        thunkActions.getDefaultBoard.typePrefix
      ] = {
        isRequesting: false,
        error,
      };
    }
  );
});
