import { Menu, MenuItem, Paper, Button, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { SimpleMenuItem } from "types";

type SimpleMenuProps = {
  anchorEl: null | HTMLElement;
  items: SimpleMenuItem[];
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  renderAnchorEl?: () => JSX.Element;
};

const MenuButtonContainer = styled(Button)`
  cursor: pointer;
`;

const noop = () => {};

const SimpleMenu = ({
  anchorEl,
  items,
  handleClose,
  handleClick,
  renderAnchorEl,
  disabled,
}: SimpleMenuProps) => {
  return (
    <Paper elevation={1} className="menu-container">
      <div
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        className="menu-button-container"
        onClick={disabled ? noop : handleClick}
      >
        {renderAnchorEl ? renderAnchorEl() : <ArrowDropDown />}
      </div>
      <Menu
        MenuListProps={{
          style: {
            border: "1px solid rgba(0,0,0,.1)",
            borderRadius: "5px",
          },
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: "translateX(10px) translateY(30px)",
          },
        }}
      >
        {items.map((item) => {
          return (
            <Tooltip title={item.tooltip || ""} key={`${item.label}-tooltip`}>
              <div>
                <MenuItem
                  disabled={item.disableButton}
                  style={{
                    borderBottom: item.hasUnderline
                      ? "1px solid rgba(0,0,0,.1)"
                      : "unset",
                  }}
                  key={item.label}
                  onClick={() => {
                    handleClose();
                    item.onClick({ item });
                  }}
                >
                  {item.label}
                </MenuItem>
              </div>
            </Tooltip>
          );
        })}
      </Menu>
    </Paper>
  );
};

export default SimpleMenu;
