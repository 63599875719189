import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { LinearBuffer } from "components/Progress";
import { createSandbox, fetchSandboxes } from "SandboxModule/thunks";
import { useDispatch, useSelector } from "react-redux";
import { plusSettingButtonProps } from "../hooks/usePlusSettingsButton";
import {
  selectCreateSandboxData,
  selectAvailableDumpFilesData,
} from "SandboxModule/selectors";
import { CreateSandboxParams } from "SandboxModule/types";
import {
  createDumpFile,
  fetchAvailableDumpFiles,
  generateRealmId,
} from "SandboxModule/thunks";
import SimpleModal from "components/SimpleModal";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IconButton } from "@material-ui/core";
import { useToggle } from "react-use";
import ListSelect from "components/ListSelect";
import { conditionallyRenderElement } from "utils";

type NewSandboxInitialValues = Omit<CreateSandboxParams, "onSuccess">;

const newSandboxInitialValues = {
  realmId: "", //
  serviceName: "", //
  fileName: "", //
  region: "", //
  domain: "", //
  /* ---------------------------------Optional----------------------------------------- */
  port: 0,
  protocol: "",
  environment: "",
};

const newDumpFileInitialValues = {
  fileName: "",
};

const Container = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  .row {
    flex: 0 1 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 1rem;
  }
  .textfield {
    flex: 1 0 60%;
    display: flex;
    align-items: stretch;
    margin-top: 1rem;
  }
  .label-text {
    margin-bottom: 0.5rem;
    flex: 1 0 40%;
    display: flex;
    align-items: flex-end;
    font-size: 1rem;
  }
  .refresh-container {
    flex: 0 1 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

const defaultRegion = "us-east-1";
const defaultDomain = "mosaicapp.com";

const useCreateSandboxModal = ({
  showCreateSandboxForm,
  handleClose,
  handleInitialOpen,
}: plusSettingButtonProps) => {
  const dispatch = useDispatch();
  const { isRequesting: isCreatingSandbox } = useSelector(
    selectCreateSandboxData
  );
  const { isRequesting: isFetchingDumpFiles, data: dumpFiles } = useSelector(
    selectAvailableDumpFilesData
  );
  const [
    newSandboxValues,
    setNewSandboxValues,
  ] = useState<NewSandboxInitialValues>(newSandboxInitialValues);
  const [newDumpFileValues, setNewDumpFileValues] = useState(
    newDumpFileInitialValues
  );
  const [isCreateNewSandboxFileModalOpen, toggleOpenModal] = useToggle(false);

  const onCloseCreateNewSandboxFileModal = () => {
    toggleOpenModal(false);
  };

  const handleOpenCreateNewSandboxFileModal = () => {
    toggleOpenModal(true);
  };

  const handleGenerateRealmId = useCallback(() => {
    dispatch(
      generateRealmId({
        onFailure: () => {
          alert("Failed to generate realm. Please try again");
        },
        onSuccess: ({ response }) => {
          setNewSandboxValues((prev) => ({
            ...prev,
            realmId: response.data,
          }));
        },
      })
    );
  }, [dispatch]);

  const submitCreateSandboxForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const params = {
      realmId: newSandboxValues.realmId,
      fileName: newSandboxValues.fileName,
      domain: defaultDomain,
      region: defaultRegion, // Hard-coded - May need it to be dynamic in the future
      onFailure: ({ error }: any) => {
        alert(
          `Failed to create new sandbox: ${newSandboxValues.realmId}. ${error.message}`
        );
      },
      onSuccess: ({ response }: any) => {
        alert(`Successfully create sandbox: ${newSandboxValues.realmId}`);
        dispatch(fetchSandboxes({}));
        handleGenerateRealmId(); // Get a new realm id after created a sandbox successful with the previous value
      },
      // serviceName: "",
      // region: "",
      // domain: "",
      // fileName: "",
    };
    alert(
      `Initiating creating new sandbox: ${newSandboxValues.realmId}. It will take a while.`
    );
    dispatch(createSandbox(params));
  };

  const onSelectSandboxFile = (values: string[]) => {
    setNewSandboxValues((prev) => ({
      ...prev,
      fileName: values[0],
    }));
  };

  const onCreateSandboxFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setNewSandboxValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateFile = () => {
    if (newDumpFileValues.fileName) {
      alert(
        `Initiating creating new dump file: ${newDumpFileValues.fileName}. It will take a while.`
      );
      dispatch(
        createDumpFile({
          fileName: newDumpFileValues.fileName,
          onFailure: ({ error }: any) => {
            alert(`Failed to create dump file. ${error.message}`);
          },
        })
      );
      setNewDumpFileValues(newDumpFileInitialValues);
    }
  };

  const refreshFileList = () => {
    dispatch(fetchAvailableDumpFiles({}));
  };

  const handleNewDumpFileInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    setNewDumpFileValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Keep the selected values for next sandbox when the modal not showing
  useEffect(() => {
    if (showCreateSandboxForm && !newSandboxValues.realmId) {
      handleGenerateRealmId();
    }
  }, [handleGenerateRealmId, newSandboxValues.realmId, showCreateSandboxForm]);

  const CreateSandboxFileModal = isCreateNewSandboxFileModalOpen && (
    <SimpleModal
      open={isCreateNewSandboxFileModalOpen}
      onClose={onCloseCreateNewSandboxFileModal}
      headerText="Create New Sandbox File"
      Body={
        <form>
          <TextField
            margin="dense"
            onChange={handleNewDumpFileInfoChange}
            value={newDumpFileValues.fileName}
            label="File Name"
            name="fileName"
            type="text"
            fullWidth
            required
            disabled={isCreatingSandbox}
          />
        </form>
      }
      onConfirm={handleCreateFile}
      confirmButtonText="Create File"
      closeOnConfirm
    />
  );

  const CreateSandboxModal = showCreateSandboxForm && (
    <Dialog
      maxWidth={"xs"}
      fullWidth
      open={showCreateSandboxForm}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title">Create new Sandbox</DialogTitle>
      <DialogContent>
        <form onSubmit={submitCreateSandboxForm}>
          <Container>
            <div className="refresh-container">
              <IconButton
                onClick={isFetchingDumpFiles ? undefined : refreshFileList}
                color="primary"
                size="small"
                edge="end"
              >
                <RefreshIcon />
              </IconButton>
            </div>
            {conditionallyRenderElement({
              isLoading: isFetchingDumpFiles,
              error: null,
              content: (
                <ListSelect
                  className="row"
                  name="fileName"
                  onChange={onSelectSandboxFile}
                  optionList={dumpFiles}
                  values={[newSandboxValues.fileName]}
                  required
                />
              ),
            })}
            <div className="row">
              {/* coming soon */}
              {/* <Button
                disabled={isCreatingSandbox}
                variant="contained"
                color="primary"
                className="button-row"
              >
                Change Realm
              </Button> */}
              <Button
                disabled={isCreatingSandbox}
                variant="contained"
                color="primary"
                className="button-row"
                onClick={handleOpenCreateNewSandboxFileModal}
              >
                Create New File
              </Button>
              <Button
                disabled={isCreatingSandbox}
                onClick={handleGenerateRealmId}
                variant="contained"
                color="primary"
                className="button-row"
              >
                Generate Realm ID (Sandbox name)
              </Button>
            </div>
            <Box className="textfield">
              <span className="label-text">Sandbox Name</span>
              <TextField
                required
                margin="dense"
                onChange={onCreateSandboxFormChange}
                value={newSandboxValues.realmId}
                label="Required"
                name="realmId"
                type="text"
                fullWidth
                disabled
              />
            </Box>
            {/* coming soon */}
            {/* <span className="label-text">Region</span>
            <div className="textfield">
              <FormControl variant="outlined" className="row">
                <TextField
                  id="region-select"
                  name="region"
                  select
                  label="Select Region"
                  value={newSandboxValues.region}
                  onChange={onCreateSandboxFormChange}
                  variant="outlined"
                >
                  {regionList.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </div> */}
          </Container>

          {/* <TextField
            margin="dense"
            onChange={onCreateSandboxFormChange}
            value={newSandboxValues.realmId}
            label="Sandbox Realm Id"
            name="realmId"
            placeholder="Enter Realm ID"
            type="text"
            fullWidth
            required
            disabled={isCreatingSandbox}
          /> */}

          <Box>{isCreatingSandbox ? <LinearBuffer /> : null}</Box>
          <DialogActions>
            <Button
              disabled={isCreatingSandbox}
              onClick={handleInitialOpen}
              color="primary"
              type="submit"
            >
              Back
            </Button>
            <Button disabled={isCreatingSandbox} color="primary" type="submit">
              Create Sandbox
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  return {
    CreateSandboxModal,
    CreateSandboxFileModal,
  };
};

export default useCreateSandboxModal;
