import {
  REPORTS_INTEGRATION_DETAILS_PATH,
  REPORTS_TABLE_TEAMS_PATH,
  LOGS_PATH,
  TEAM_PROFILE_PATH,
  REPORTS_PENDING_ENTITY_PATH,
  INTEGRATION_HEALTH_PATH,
  SANDBOXES_PATH,
  MEMBER_TABLE_VIEWS,
  DEFAULT_MEMBER_TABLE_VIEW,
  TEAM_MANAGEMENT_PATH,
  REPORTS_VIEW_TYPES,
  DEFAULT_REPORTS_VIEW_TYPES,
  REPORTS_OVERALL_INTEGRATION_DETAILS_PATH,
  DATA_MANAGEMENT_PATH,
} from "@constants";
import { ValueOf } from "type-fest";

export const getIntegrationDetailsDeepLinkUrl = (
  teamId: string | number,
  targetService: string,
  targetServiceId: string | number
) => {
  return `${REPORTS_INTEGRATION_DETAILS_PATH}/team/${teamId}/targetService/${targetService}/targetServiceId/${targetServiceId}`;
};

export const getIntegrationHealthDeepLinkUrl = (
  teamId: string | number,
  targetService: string,
  targetServiceId: string | number
) => {
  return `${INTEGRATION_HEALTH_PATH}/team/${teamId}/targetService/${targetService}/targetServiceId/${targetServiceId}`;
};

export const getTeamProfileUrl = ({
  teamId,
  memberTableView = DEFAULT_MEMBER_TABLE_VIEW,
}: {
  teamId: number;
  memberTableView?: ValueOf<typeof MEMBER_TABLE_VIEWS>;
}) => `${TEAM_PROFILE_PATH}/${teamId}/${memberTableView}`;

export const getPendingEntitiesUrl = ({
  targetServiceId,
}: {
  targetServiceId: number;
}) => `${REPORTS_PENDING_ENTITY_PATH}/${targetServiceId}`;

export const getNetworkLogUrl = ({
  mosaic_team_id,
  requestId,
  sessionId,
}: {
  mosaic_team_id: number;
  requestId: string | null;
  sessionId: string;
}) =>
  `${LOGS_PATH}/${mosaic_team_id}?requestId=${requestId}&sessionId=${sessionId}`;

export const getSandboxesTableUrl = () => SANDBOXES_PATH;

export const getReportTeamsPageDeepLinkUrl = ({
  reportTableView = DEFAULT_REPORTS_VIEW_TYPES,
}: {
  reportTableView?: ValueOf<typeof REPORTS_VIEW_TYPES>;
}) => {
  return `${REPORTS_TABLE_TEAMS_PATH}/${reportTableView}`;
};

export const getOverallIntegrationUrl = (queryParams?: string) => {
  return `${REPORTS_OVERALL_INTEGRATION_DETAILS_PATH}?${queryParams}`;
};
export const getTeamManagementPageUrl = () => {
  return TEAM_MANAGEMENT_PATH;
};

export const getDataManagementPageUrl = () => {
  return DATA_MANAGEMENT_PATH;
};
