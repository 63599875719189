import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled, { withTheme } from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon, AlertCircle } from "react-feather";
import { debounce } from "lodash";
import cn from "classnames";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  Box,
  Button,
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import { Menu as MenuIcon } from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import {
  toggleDevMode as _toggleDevMode,
  setSelectedRailsSandbox,
} from "actionCreators";

import {
  selectIsDevMode,
  selectReportsTeamsData,
  selectRailsSandboxesData,
  selectTeamsUtilizationData,
} from "selectors";

import { fetchReportsTeams, fetchTeamsHistoricalUtilization } from "thunk";

import { getReportsTeamSearchValue } from "utils";

import useAppNavigation from "hooks/useAppNavigation";
import useOnSuccess from "hooks/useOnSuccess";
import useBulkActionsSelector from "hooks/useBulkActionsSelector";
import { Sandbox } from "types";
import { REPORTS_VIEW_TYPES } from "@constants";
import { useRouteMatch } from "react-router-dom";
import ApiClientManager from "core/apiClient";

const defaultRailsSandbox = ApiClientManager.getDefaultRailsSandbox();

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 300px;
  }
`;

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => {
  const dispatch = useDispatch();
  const toggleDevMode = () => dispatch(_toggleDevMode());
  const isDevMode: boolean = useSelector(selectIsDevMode);
  const {
    params: { memberTableView, reportTableView },
  } = useRouteMatch<{ memberTableView?: string; reportTableView?: string }>();
  const { isRequesting: isFetchingTeams, filter } = useSelector(
    selectReportsTeamsData
  );
  const {
    isRequesting: isFetchingTeamsUtilization,
    error: fetchTeamsUtilizationError,
    filter: teamsUtilizationFilter,
  } = useSelector(selectTeamsUtilizationData);
  const { navigateToTeamsTable, navigateToTeamProfile } = useAppNavigation();
  const {
    onReportTeamsFetchSuccess,
    onReportsTeamUtilizationFetchSuccess,
  } = useOnSuccess();
  const {
    allRealmIds,
    sandboxHash,
    selectedRailsSandbox,
    isRequesting: isFetchingSandboxes,
  } = useSelector(selectRailsSandboxesData);

  const handleSelectSandbox = (sandbox: Sandbox) => {
    dispatch(setSelectedRailsSandbox({ sandbox }));
    // Refresh page after selecting a sandbox to start a new sandbox session
    navigateToTeamsTable();
    window.location.reload();
  };

  const { BulkActionsSelector: SandboxSelector } = useBulkActionsSelector({
    selectedItemsIds: [],
    menuItems: [
      {
        label: defaultRailsSandbox.realm_id,
        onClick: () => {
          handleSelectSandbox(defaultRailsSandbox);
        },
        disableButton: isFetchingSandboxes,
      },
      ...allRealmIds.reduce(
        (
          acc: {
            label: string;
            onClick: () => void;
            disableButton: boolean;
          }[],
          realmId: string
        ) => {
          const sandbox = sandboxHash[realmId];

          if (sandbox?.is_ready) {
            const item = {
              label: realmId,
              onClick: () => {
                if (sandbox && realmId !== selectedRailsSandbox.realm_id) {
                  handleSelectSandbox(sandbox);
                }
              },
              disableButton: isFetchingSandboxes,
            };
            acc.push(item);
          }

          return acc;
        },
        []
      ),
    ],
    anchorElProps: {
      isDisabled: isFetchingSandboxes,
      renderCustomAnchorEl: ({ name }) => (
        <Tooltip title={isFetchingSandboxes ? "Loading sandboxes..." : ""}>
          <StyledButton
            className={cn("sandbox", {
              loading: isFetchingSandboxes,
            })}
            variant="outlined"
            color="primary"
          >
            {selectedRailsSandbox?.realm_id}
          </StyledButton>
        </Tooltip>
      ),
    },
  });

  const inputRef = useRef(document.createElement("input"));

  const isRequesting = isFetchingTeams || isFetchingTeamsUtilization;

  const onEnterPress = debounce((key: string) => {
    const search_text = inputRef.current.value.trim();
    if (key === "Enter" && search_text) {
      const valueToSearch = getReportsTeamSearchValue(search_text);
      const { ids } = valueToSearch;

      if (ids.length) {
        const navigateToProfile = navigateToTeamProfile(
          ids[0],
          memberTableView
        );
        navigateToProfile();
        return;
      }

      // If on another page, or on reports page but on statistics tab
      if (
        !reportTableView ||
        reportTableView === REPORTS_VIEW_TYPES.COUNT_STATISTICS
      ) {
        dispatch(
          fetchReportsTeams({
            ...filter,
            ...valueToSearch,
            isInitialFetch: true,
            isSearchMode: true,
            show_only_deactivated: false,
            show_deactivation_info: false,
          })
        );
        navigateToTeamsTable(REPORTS_VIEW_TYPES.TEAMS);
      } else if (reportTableView === REPORTS_VIEW_TYPES.UTILIZATION) {
        dispatch(
          fetchTeamsHistoricalUtilization({
            ...teamsUtilizationFilter,
            onSuccess: onReportsTeamUtilizationFetchSuccess,
            offset: 0,
            isInitialFetch: true,
          })
        );
      } else {
        // Deactivated or Active customers tab
        const shouldShowDeactivateTeams =
          reportTableView === REPORTS_VIEW_TYPES.DEACTIVATED_TEAMS;

        dispatch(
          fetchReportsTeams({
            ...filter,
            ...valueToSearch,
            isInitialFetch: true,
            isSearchMode: true,
            show_only_deactivated: shouldShowDeactivateTeams,
            show_deactivation_info: shouldShowDeactivateTeams,
          })
        );
      }
    }
  }, 100);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input
                  disabled={isRequesting}
                  onKeyDown={(e) => {
                    const { key } = e;
                    onEnterPress(key);
                  }}
                  inputRef={inputRef}
                  placeholder={
                    isRequesting
                      ? "Loading..."
                      : "Search team by ID, name or member email"
                  }
                />
              </Search>
            </Grid>
            <Tooltip title="If the team name contains mostly number, such as 123123123, put a ' in front of the input. (i.e: '123123123)">
              <AlertCircle />
            </Tooltip>
            <Grid item xs />
            <Grid item>
              {ApiClientManager.getIsSandboxEnabled() && (
                <Box display={"inline-block"}>{SandboxSelector}</Box>
              )}
              <Switch
                onChange={toggleDevMode}
                checked={isDevMode}
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span style={{ display: isDevMode ? "inline" : "none" }}>
                <MessagesDropdown />
                <NotificationsDropdown />
                <LanguagesDropdown />
              </span>
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);

const StyledButton = styled(Button)`
  &.sandbox {
    &.loading {
      cursor: not-allowed !important;
      border: 1px solid lightgrey !important;
      color: lightgrey !important;
    }
  }
`;
