import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { integrationsHash } from "@constants";

type PathInstance = object | string | number;

const useUrl = () => {
  const pathParams: Record<any, any> = useParams(); // /:slug
  const queryParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  /**
   * A method that takes a params object and uses the key value as query string
   * Replaces the current query string
   * @param params
   */
  const pushQueryParams = (params = {}) => {
    const newQueryString = new URLSearchParams(params).toString();
    history.push({
      search: `?${newQueryString}`,
    });
  };

  /**
   *
   * @param paths
   * If it is a string, then use it as a path
   * If it is an object, use it as a path query
   * I.e: [['/reports/teams', {
      'teamId': 4828
    }, '/reportsV2', {
      'teamId':123
    }]] => <domain>:port/reports/teams/4828/reportsV2/123
   * @param queryParams
   */
  const pushPathParams = (
    paths: Array<PathInstance> = [],
    queryParams = {}
  ) => {
    const newPathNameString = paths
      .reduce((acc: Array<string>, curr: PathInstance) => {
        if (typeof curr === "string") {
          acc.push(curr);
        } else {
          const pathValue = Object.values(curr);
          acc.push(`/${pathValue}`);
        }
        return acc;
      }, [])
      .join("");
    const newQueryString = new URLSearchParams(queryParams).toString();
    history.push({
      pathname: newPathNameString,
      search: `?${newQueryString}`,
    });
  };

  const targetService = pathParams?.["targetService"] || "";
  const targetServiceId = pathParams?.["targetServiceId"] || "";
  const integration = integrationsHash[targetService];
  const teamId = pathParams["teamId"] || "";

  const queryTargetService = queryParams?.get("targetService") || "";
  const queryInterval = queryParams?.get("interval") || "";

  return {
    pathParams,
    queryParams,
    pushQueryParams,
    pushPathParams,
    integration,
    targetServiceId,
    targetService,
    teamId,
    /* -------------------------------------------------------------------------- */
    queryInterval,
    queryTargetService,
  };
};

export default useUrl;
