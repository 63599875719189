import styled from "styled-components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { plusSettingButtonProps } from "../hooks/usePlusSettingsButton";
import { trialDurationArray, trialDurationHash } from "GlobalModule/constants";
import { useToggle } from "react-use";
import { makeObjectWithGalaxyProps, conditionallyRenderElement } from "utils";
import { integrations, integrationsHash, REMOVE_TAG_TEXT } from "@constants";
import { createTeam } from "GlobalModule/thunks";
import { selectRecurlyPlans, selectCreateTeamData } from "selectors";
import SingleValuePickerV2 from "components/SingleValuePickerV2";
import omit from "lodash/omit";
import { LinearBuffer } from "components/Progress";
import EllipsisText from "components/EllipsisText";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  Chip,
  Divider,
} from "@material-ui/core";

const DividerBox = styled(Box)`
  margin-left: -2rem;
  margin-top: 1rem;
  width: calc(100% + 55px);

  .divider {
    background-color: black;
  }
`;

const TagContainer = styled.div`
  display: flex;
  .add-tag-div {
    flex: 2;
    min-width: 80px;
  }
  .initial-tag-div {
    flex: 2;
  }
  .start-date {
    flex: 2;
  }
`;

const ChipsContainer = styled.div`
  margin-bottom: 15px;
  .chip {
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: 370px;
    height: 27px;
  }
  .red-chip {
    background-color: #f44336;
    color: white;
  }
`;

type SelectedIntegrationType = {
  uid: string;
  fee: string;
  discount: string;
  id: string; // integration-id
};

type initialValuesType = {
  email: string;
  company: string;
  skip_credit_card_info: boolean;
  trial_duration: number;
  sales_force_id: string;
  sales_force_link: string;
  tags: string[];

  // /* -------------------------------------------------------------------------- */
  // skipNumUsers: boolean;
  // numUsers: number;
  // /* -------------------------------------------------------------------------- */
  // plan: string;
  // /* -------------------------------------------------------------------------- */
  // fee: number;
  // // skipFee: boolean;
  // /* -------------------------------------------------------------------------- */
  // discount: number; // A dropdown from API
  // skipDiscount: boolean;
  // /* -------------------------------------------------------------------------- */
  // enableOnboarding: boolean;
  // onboardingDiscount: number;
  // onboardingFee: string;

  // /* -------------------------------------------------------------------------- */
  // enableIntegration: boolean;
  // selectedIntegrations: SelectedIntegrationType[];
  // /* -------------------------------------------------------------------------- */
};

const getNewFee = ({
  numUsers,
  unitAmount,
  discount,
}: {
  numUsers: number;
  unitAmount: number;
  discount: number;
}) => {
  const unDiscountedFee = +numUsers * unitAmount;
  const newFee = unDiscountedFee - unDiscountedFee * (discount / 100);
  return Math.ceil(newFee);
};

const noop = () => {};

const DividerDiv = () => {
  return (
    <DividerBox>
      <Divider className="divider" />
    </DividerBox>
  );
};

const chipWidth = "200px";

const useCreateTeamModal = ({
  showCreateTeamsForm,
  handleClose,
  handleInitialOpen,
}: plusSettingButtonProps) => {
  const [isCreateTeamModalOpen, toggleOpen] = useToggle(false);
  const dispatch = useDispatch();
  const { plansHash, formattedPlans } = useSelector(selectRecurlyPlans);
  const { isRequesting } = useSelector(selectCreateTeamData);
  const initialValues = {
    email: "",
    company: "",
    skip_credit_card_info: false,
    trial_duration: trialDurationHash[15].value,
    sales_force_id: "",
    sales_force_link: "",
    tags: [],
    // /* -------------------------------------------------------------------------- */
    // skipNumUsers: false,
    // numUsers: 0, // text optional
    // /* -------------------------------------------------------------------------- */
    // plan: "", // Required ss -> Populated by plan API
    // /* -------------------------------------------------------------------------- */
    // fee: 0, // is populated by an API call that takes the selected values of: 1) # of users, 2) plan, 3) discount and returns the Fee amount.
    // // skipFee: false,
    // // This value should update any time one fo these three fields changes
    // /* -------------------------------------------------------------------------- */
    // discount: dummyDiscountHash[0].value, // A dropdown from API
    // skipDiscount: false,
    // /* -------------------------------------------------------------------------- */
    // enableOnboarding: false,
    // onboardingDiscount: dummyDiscountHash[0].value,
    // onboardingFee: "Read only onboarding fee",

    // /* -------------------------------------------------------------------------- */
    // enableIntegration: false,
    // integrationSelected: "",
    // integrationFee: "Read only integration fee",
    // integrationDiscount: dummyDiscountHash[0].value,
    // selectedIntegrations: [],
  };

  const [createTeamFormFields, setFields] = useState<initialValuesType>(
    initialValues
  );

  // const { plan, discount, numUsers } = createTeamFormFields;

  // useEffect(() => {
  //   const planAddOn = plansHash[plan]?.add_on?.add_on_prices?.[0];
  //   if (plansHash && plan) {
  //     const { unit_amount = 1 } = planAddOn || {};
  //     setFields((prev) => ({
  //       ...prev,
  //       fee: getNewFee({ numUsers, unitAmount: unit_amount, discount }),
  //     }));
  //   }
  // }, [plan, discount, numUsers, plansHash]);

  const isCreatingTeam = isRequesting;

  // const disableNumUsers = isCreatingTeam || createTeamFormFields.skipNumUsers;

  // const disableDiscount = isCreatingTeam || createTeamFormFields.skipDiscount;

  // const disableFee = isCreatingTeam || createTeamFormFields.skipFee;

  // const showOnboardingFeeAndDiscount =
  //   createTeamFormFields.enableOnboarding &&
  //   !!+createTeamFormFields.numUsers &&
  //   !disableNumUsers;

  // const fieldStatusHash = {
  //   disableNumUsers,
  //   disableDiscount,
  //   // disableFee,
  //   showOnboardingFeeAndDiscount,
  // };

  // const onIntegrationChange = (name: string, value: string) => {
  //   const [_, keyToChange, selectedUid] = name.split("_");
  //   setFields((prev) => ({
  //     ...prev,
  //     selectedIntegrations: prev.selectedIntegrations.map((integration) =>
  //       selectedUid === integration.uid
  //         ? {
  //             ...integration,
  //             [keyToChange]: value,
  //           }
  //         : integration
  //     ),
  //   }));
  // };

  const onCreateTeamFormChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value, type, checked } = event.target;
    if (name.includes("integration")) {
      // onIntegrationChange(name, value);
      return;
    }
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : isNaN(+value) ? value : +value,
    }));
  };

  const submitCreateTeamForm = () => {
    const newTeamFields = omit(createTeamFormFields, [
      "sales_force_id",
      "sales_force_link",
      "tags",
    ]);
    const params = {
      ...newTeamFields,
      galaxy_props: {
        sales_force_id: createTeamFormFields["sales_force_id"],
        sales_force_link: createTeamFormFields["sales_force_id"],
        tags: createTeamFormFields["tags"],
      },
    };
    // const { numUsers } = createTeamFormFields;
    // console.info(+numUsers); // do this for numUser to convert
    dispatch(createTeam(params));
  };

  const [initialTag, setInitialTag] = useState({
    "50+": false,
    "100+": false,
    "self-serve": false,
  });
  const [tagInput, setTagInput] = useState("");
  const tagCheckboxList = [
    {
      name: "self-serve",
      checked: initialTag["self-serve"],
      label: "Self-serve",
      value: "self-serve",
    },
    {
      name: "50+",
      checked: initialTag["50+"],
      label: "50+",
      value: "50+",
    },
    {
      name: "100+",
      checked: initialTag["100+"],
      label: "100+",
      value: "100+",
    },
  ];
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagInput(event.target.value);
  };

  const [tagsList, setTagsList] = useState<string[]>([]);

  const updateTagList = (tag: string) => {
    const newTagsList = [...tagsList, tag];
    setTagsList(newTagsList);
    setFields({ ...createTeamFormFields, tags: newTagsList });
  };

  const addTag = (tag: string) => (e: any) => {
    const trimmedTag = tag.trim().toLowerCase();
    if (!trimmedTag) return;
    if (e.key === "Enter") {
      if (tagsList.indexOf(trimmedTag) === -1) {
        setInitialTag({ ...initialTag, [tag]: true });
        updateTagList(trimmedTag);
      }
      setTagInput("");
    }
  };

  const removeTag = (tagToRemove: string) => {
    const newList = tagsList.filter((item) => item !== tagToRemove);
    setInitialTag({ ...initialTag, [tagToRemove]: false });
    setTagsList(newList);
    setFields({ ...createTeamFormFields, tags: newList });
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<{ name: string; checked: boolean; value: string }>
  ) => {
    setInitialTag({ ...initialTag, [event.target.name]: event.target.checked });
    if (event.target.checked) {
      updateTagList(event.target.value);
    } else {
      removeTag(event.target.value);
    }
  };

  const onDelete = (tag: string) => {
    if (isCreatingTeam) {
      return;
    }
    removeTag(tag);
  };

  const CreateTeamModal = showCreateTeamsForm && (
    // const {
    //   disableNumUsers,
    //   disableDiscount,
    //   // disableFee,
    //   showOnboardingFeeAndDiscount,
    // } = fieldStatusHash;
    // const { selectedIntegrations } = createTeamFormFields;
    // Question: If we skil number of users, then all
    /**  Seems the spec is incomplete, we don't know what to send to API
     * 1) If skip, then just don't include that in the API when available?
     * 2) Fee is read-only, so what's the point of skipping? Also, if one of the 3 fields are skipped, do we skip Fee as well?
     * 4) How to populate discount + onboarding discount?
     * 7) Is Fee and Onboarding calculated differently?
     * 5) If I skip Number user with numbered input, the onboarding fee and discount will also be skipped in the API ok
     * 6) Is the add-on_price of any use?
     * 8) When selecting integrations, can they select multiple of the same type like 2 QB desktop and 2 csv agents?
     */ <Dialog
      maxWidth="sm"
      fullWidth
      open
      onClose={isCreatingTeam ? noop : handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create new team</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter information to sign up for a new team
        </DialogContentText>
        <form>
          <TextField
            autoFocus
            margin="dense"
            name="email"
            onChange={onCreateTeamFormChange}
            value={createTeamFormFields.email}
            label="Email Address"
            type="email"
            fullWidth
            placeholder="Email"
            disabled={isCreatingTeam}
            required
          />
          <TextField
            margin="dense"
            onChange={onCreateTeamFormChange}
            value={createTeamFormFields.company}
            label="Company name"
            name="company"
            placeholder="My Company"
            type="text"
            fullWidth
            disabled={isCreatingTeam}
          />
          <Box display={"flex"}>
            <TextField
              style={{ paddingRight: "1rem" }}
              margin="dense"
              onChange={onCreateTeamFormChange}
              value={createTeamFormFields.sales_force_link}
              label="Salesforce link"
              name="sales_force_link"
              placeholder="Salesforce Link"
              type="text"
              fullWidth
              disabled={isCreatingTeam}
            />
            <TextField
              margin="dense"
              onChange={onCreateTeamFormChange}
              value={createTeamFormFields.sales_force_id}
              label="Salesforce ID"
              name="sales_force_id"
              placeholder="Salesforce ID"
              type="text"
              fullWidth
              disabled={isCreatingTeam}
            />
          </Box>
          <Box
            display="flex"
            mt={"1rem"}
            alignItems={"center"}
            justifyContent="flex-start"
          >
            <FormControlLabel
              control={<Checkbox onChange={onCreateTeamFormChange} />}
              disabled={isCreatingTeam}
              value={createTeamFormFields.skip_credit_card_info}
              checked={createTeamFormFields.skip_credit_card_info}
              label="Skip credit card information"
              name="skip_credit_card_info"
              style={{ width: "70%" }}
            />
            <FormControl fullWidth>
              <SingleValuePickerV2
                items={trialDurationArray}
                itemsHash={trialDurationHash}
                item={createTeamFormFields.trial_duration}
                inputLabel="Trial Duration"
                inputName="trial_duration"
                onValueChange={onCreateTeamFormChange}
                fullWidth
                disabled={isCreatingTeam}
              />
            </FormControl>
          </Box>

          <DividerDiv />
          {/* ----------------------------------- add tags ---------------------------------- */}

          <FormControl component="fieldset" fullWidth>
            <Box component="h3">Add tags to this team</Box>
            <TagContainer>
              <div className="initial-tag-div">
                <FormGroup>
                  {tagCheckboxList.map((item) => (
                    <FormControlLabel
                      key={`${item.value}-${item.checked}`}
                      control={
                        <Checkbox
                          checked={item.checked}
                          onChange={handleCheckboxChange}
                          name={item.name}
                          value={item.value}
                          disabled={isCreatingTeam}
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>
              <div className="add-tag-div">
                <TextField
                  value={tagInput}
                  onKeyDown={addTag(tagInput)}
                  helperText="Tags"
                  onChange={handleInputChange}
                  disabled={isCreatingTeam}
                />
                <ChipsContainer>
                  {tagsList.map((tag) => (
                    <EllipsisText text={tag} width={chipWidth}>
                      <Chip
                        color="primary"
                        className={
                          tag === REMOVE_TAG_TEXT ? "chip red-chip" : "chip"
                        }
                        label={tag}
                        onDelete={() => onDelete(tag)}
                        style={{ maxWidth: `calc(${chipWidth} - 10%)` }}
                      />
                    </EllipsisText>
                  ))}
                </ChipsContainer>
              </div>
              {/* ----------------------------------- for start date ---------------------------------- */}
              {/* <div className="start-date">
                <TextField
                  value={tagInput}
                  onKeyDown={addTag(tagInput)}
                  helperText="Start Date"
                  onChange={handleInputChange}
                  disabled={isCreatingTeam}
                  type="date"
                />
              </div> */}
            </TagContainer>
          </FormControl>

          {/* <DividerDiv /> */}
          {/* ----------------------------------- Plan ---------------------------------- */}
          {/* <Box component="h3">Plan</Box>
            <Box display={"flex"} alignItems="center" gridGap={"1rem"}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={isCreatingTeam}
                onChange={onCreateTeamFormChange}
                checked={createTeamFormFields.skipFee}
                label="Skip"
                name="skipFee"
              />
              <TextField
                margin="dense"
                //   onChange={onCreateTeamFormChange}
                value={"Read only fee"} // createTeamFormFields.fee
                label="Fee (Read-only)"
                name="fee"
                placeholder="Fee"
                type="text"
                fullWidth
                disabled
                // disabled={disableFee}
              />
            </Box>
            <Box display={"flex"} alignItems="center" gridGap={"1rem"}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={isCreatingTeam}
                onChange={onCreateTeamFormChange}
                checked={createTeamFormFields.skipDiscount}
                label="Skip"
                name="skipDiscount"
              />
              <SingleValuePickerV2
                items={dummyDiscount}
                itemsHash={dummyDiscountHash}
                item={createTeamFormFields.discount}
                inputLabel="Discount"
                inputName="discount"
                fullWidth
                onValueChange={onCreateTeamFormChange}
                disabled={disableDiscount}
              />
            </Box>
            <Box display={"flex"} alignItems="baseline" gridGap={"1rem"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createTeamFormFields.skipNumUsers}
                    onChange={onCreateTeamFormChange}
                    inputProps={{
                      name: "skipNumUsers",
                    }}
                  />
                }
                disabled={isCreatingTeam}
                onChange={onCreateTeamFormChange}
                checked={createTeamFormFields.skipNumUsers}
                label="Skip"
                name="skipNumUsers"
              />
              <NumberFormat
                margin="dense"
                onChange={onCreateTeamFormChange}
                value={createTeamFormFields.numUsers}
                label="Number of Users"
                name="numUsers"
                placeholder="Number of Users"
                customInput={TextField}
                disabled={disableNumUsers}
                allowNegative={false}
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" gridGap={"5px"}>
              <FormControl fullWidth required>
                <SingleValuePickerV2
                  items={formattedPlans}
                  itemsHash={plansHash}
                  item={createTeamFormFields.plan}
                  inputLabel="Plan"
                  inputName="plan"
                  onValueChange={onCreateTeamFormChange}
                  fullWidth
                />
              </FormControl>
              <TextField
                margin="dense"
                value={createTeamFormFields.fee}
                label="Fee (Read-only)"
                name="fee"
                placeholder="Fee"
                type="text"
                fullWidth
                disabled
              />
            </Box>
  
            <Box component={"div"} marginTop="1rem">
              Choose plan first
            </Box>
            <DividerDiv /> */}
          {/* ------------------------------- Onboarding ------------------------------- */}
          {/* <Box component="h3">Onboarding</Box>
            <Box display="flex" alignItems={"center"} gridGap="8px">
              <FormControlLabel
                control={<Checkbox />}
                disabled={isCreatingTeam}
                onChange={onCreateTeamFormChange}
                checked={createTeamFormFields.enableOnboarding}
                label="Onboarding"
                name="enableOnboarding"
              />
              {showOnboardingFeeAndDiscount && (
                <Box
                  display={"flex"}
                  alignItems="center"
                  gridGap={"10px"}
                  flex="1"
                >
                  <Box width={'100%'} paddingTop='3px'>
                    <SingleValuePickerV2
                      items={dummyDiscount}
                      itemsHash={dummyDiscountHash}
                      item={createTeamFormFields.onboardingDiscount}
                      inputLabel="Onboarding Discount"
                      inputName="onboardingDiscount"
                      fullWidth
                      onValueChange={onCreateTeamFormChange}
                    />
                  </Box>
                  <SingleValuePickerV2
                    items={dummyDiscount}
                    itemsHash={dummyDiscountHash}
                    item={createTeamFormFields.onboardingDiscount}
                    inputLabel="Onboarding Discount"
                    inputName="onboardingDiscount"
                    fullWidth
                    paddingTop="3px"
                    onValueChange={onCreateTeamFormChange}
                  />
                  <TextField
                    onChange={onCreateTeamFormChange}
                    value={createTeamFormFields.onboardingFee}
                    label="Onboarding Fee (Read-only)"
                    name="onboardingFee"
                    placeholder="Onboarding Fee"
                    type="text"
                    disabled
                    fullWidth
                  />
                </Box>
              )}
            </Box>
            <DividerDiv /> */}
          {/* ------------------------------- Integrations ------------------------------- */}
          {/* <Box component="h3">Integration</Box>
            <Box component={"div"} m={"1rem 0"}>
              <FormControlLabel
                control={<Checkbox />}
                disabled={isCreatingTeam}
                onChange={onCreateTeamFormChange}
                checked={createTeamFormFields.enableIntegration}
                label="Integrations"
                name="enableIntegration"
              />
            </Box>
            {selectedIntegrations.map(
              ({ uid, id, discount }: SelectedIntegrationType) => (
                <Box
                  key={uid}
                  display={"flex"}
                  alignItems={"flex-end"}
                  justifyContent="space-between"
                  m={"1rem 0"}
                  gridGap="3px"
                >
                  <SingleValuePickerV2
                    items={integrations}
                    itemsHash={integrationsHash}
                    item={id || ""}
                    inputLabel="Integration"
                    inputName={`integration_id_${uid}`}
                    onValueChange={onCreateTeamFormChange}
                    disabled={!createTeamFormFields.enableIntegration}
                    style={{ width: "140%" }}
                    fullWidth
                  />
                  <SingleValuePickerV2
                    items={dummyDiscount}
                    itemsHash={dummyDiscountHash}
                    item={discount || dummyDiscount[0].value}
                    inputLabel="Integration Discount"
                    inputName={`integration_discount_${uid}`}
                    onValueChange={onCreateTeamFormChange}
                    disabled={!createTeamFormFields.enableIntegration}
                    fullWidth
                  />
                  <TextField
                    onChange={onCreateTeamFormChange}
                    value={"To calc"} // Must change somehow
                    label="Integration Fee"
                    name={`integration_fee_${uid}`}
                    placeholder="Integration Fee"
                    type="text"
                    disabled
                    fullWidth
                  />
                  <Button
                    onClick={onRemoveIntegration(uid)}
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{ minWidth: "0" }}
                  >
                    <RemoveIcon />
                    {/* <Box component={'button'} onClick={onRemoveIntegration(uid)}>
                    <RemoveIcon />
                  </Box> 
                </Box> 
                  </Box> 
                </Box> 
                  </Box> 
                  </Button>
                </Box>
              )
            )}*/}
          {/* <Button color="primary" onClick={onAddIntegration}>
              + Integration
            </Button>*/}
          <Box>
            {" "}
            {/* {conditionallyRenderElement({
                isLoading: isCreatingTeam,
                error: null,
                content: (
                  <Alert
                  onClose={closeAlert}
                  severity={
                    `${
                      createNewTeamSuccessMessage ? "success" : "error"
                    }` as any
                  }
                  >
                    This should only show if successful API call
                    {createNewTeamSuccessMessage || createNewTeamErrorMessage} 
                  {createNewTeamSuccessMessage || createNewTeamErrorMessage} 
                    {createNewTeamSuccessMessage || createNewTeamErrorMessage} 
                  {createNewTeamSuccessMessage || createNewTeamErrorMessage} 
                    {createNewTeamSuccessMessage || createNewTeamErrorMessage} 
                  </Alert>
                ),
              })} */}
            {isCreatingTeam ? (
              <LinearBuffer />
            ) : (
              // showMessage ? (
              //   <Alert
              //     onClose={closeAlert}
              //     severity={
              //       `${createNewTeamSuccessMessage ? "success" : "error"}` as any
              //     }
              //   >
              //     {createNewTeamSuccessMessage || createNewTeamErrorMessage}
              //   </Alert>
              // ) :
              ""
            )}
          </Box>
          <DialogActions>
            <Button
              disabled={isCreatingTeam}
              onClick={handleInitialOpen}
              color="primary"
            >
              Back
            </Button>
            <Button
              disabled={isCreatingTeam}
              color="primary"
              onClick={submitCreateTeamForm}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );

  return {
    CreateTeamModal,
  };
};

export default useCreateTeamModal;
