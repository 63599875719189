import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  fetchTeamsHistoricalUtilization,
  fetchIntegrationHealth,
  fetchTeamsForTeamsInfoMapping,
} from "thunk";
import { GenericTeamInterface, IntegrationListInterface } from "types";
import * as IntegrationTypes from "IntegrationModule/types";
import * as ReportsType from "ReportsModule/types";
import { setFilters } from "actionCreators";
import * as constants from "@constants";
import moment from "moment";
import { formatIntegrationHealthDateParams } from "utils";
import { integrationHealthIsoStateModifiers } from "IntegrationModule/utils";

const integrationHealthIsoStateIds = constants.integrationHealthIsoStateIds;

type FetchTeamsResponse = {
  data: {
    teams: GenericTeamInterface[];
  };
};

const useOnSuccess = () => {
  const dispatch = useDispatch();

  const onReportTeamsFetchSuccess = [
    {
      action: (teamIds: number[] = []) => {
        dispatch(
          fetchTeamsHistoricalUtilization({
            team_ids: teamIds,
            isInitialFetch: true,
            offset: 0,
            threshold: constants.REPORT_TEAMS_UTILIZATION_THRESHOLD,
            compliant_start_period:
              constants.REPORT_TEAMS_UTILIZATION_COMPLIANT_START_DATE,
          })
        );
      },
      selector: ({ response }: { response: FetchTeamsResponse }) => {
        return response.data.teams.map((team) => team.id);
      },
    },
  ];

  const onReportsTeamUtilizationFetchSuccess = [
    {
      action: (teamIds: number[] = []) => {
        dispatch(
          fetchTeamsForTeamsInfoMapping({
            teamIds,
          })
        );
      },
      selector: ({ response }: { response: any }) => {
        return Object.keys(response.data);
      },
    },
    {
      action: ({
        params,
        response,
      }: {
        response: any;
        params: ReportsType.fetchTeamsHistoricalUtilizationParams;
      }) => {
        dispatch(
          setFilters({
            filterId: constants.filterIds.teamsUtilizationReports,
            offset: params.offset,
            page: params.page,
            // total: 1000, //(response?.total as number) || 0 => Just make sure to check after BE made the change
            /**
             * TODO
             * - If we ever want to use the default pagination (back and forth)
             * - Then need BE to return a total, then use that as the value for value "total" above
             * - Then use that as a total count for paginating
             * - List slicing can be done in selectTeamsUtilizationData
             */
          })
        );
      },
      selector: ({
        response,
        params,
      }: {
        response: any;
        params: ReportsType.fetchTeamsHistoricalUtilizationParams;
      }) => {
        return {
          response,
          params,
        };
      },
    },
  ];

  const onTeamIntegrationListFetchSuccess = useMemo(
    () => [
      {
        selector: ({ response }: Record<string, any>) => {
          return response.data;
        },
        action: (integrationList: IntegrationListInterface[]) => {
          const targetServiceToIdsHash: Record<string, number[]> = {};

          integrationList?.forEach((integration) => {
            const { targetService, targetServiceId } = integration;
            if (targetServiceToIdsHash[targetService]) {
              targetServiceToIdsHash[targetService].push(targetServiceId);
            } else {
              targetServiceToIdsHash[targetService] = [targetServiceId];
            }
          });

          Object.entries(targetServiceToIdsHash).forEach(
            ([targetService, targetServiceIds]) => {
              dispatch(
                fetchIntegrationHealth({
                  targetService,
                  targetServiceIds,
                  serviceNames: [targetService],
                  startDate: formatIntegrationHealthDateParams(moment()),
                  endDate: formatIntegrationHealthDateParams(moment()),
                  isoStateId:
                    integrationHealthIsoStateIds.TeamProfileIntegrationList,
                  modifier:
                    integrationHealthIsoStateModifiers[
                      integrationHealthIsoStateIds.TeamProfileIntegrationList
                    ],
                })
              );
            }
          );
        },
      },
    ],
    [dispatch]
  );

  const onTargetServiceMetricsFetchSuccess = useMemo(
    () => [
      {
        selector: ({ response, targetService }: Record<string, any>) => {
          return { teams: response.data?.teams || [], targetService };
        },
        action: ({
          teams,
          targetService,
        }: {
          teams: IntegrationTypes.ServiceMetricsAPIResponseData["teams"];
          targetService: string;
        }) => {
          const targetServiceIds = teams.map((team) => team.targetServiceId);

          dispatch(
            fetchIntegrationHealth({
              targetService,
              targetServiceIds,
              serviceNames: [targetService],
              startDate: formatIntegrationHealthDateParams(moment()),
              endDate: formatIntegrationHealthDateParams(moment()),
              isoStateId:
                integrationHealthIsoStateIds.SyncRatioTable,
              modifier:
                integrationHealthIsoStateModifiers[
                  integrationHealthIsoStateIds.SyncRatioTable
                ],
            })
          );
        },
      },
    ],
    [dispatch]
  );

  return {
    onReportTeamsFetchSuccess,
    onTeamIntegrationListFetchSuccess,
    onReportsTeamUtilizationFetchSuccess,
    onTargetServiceMetricsFetchSuccess,
  };
};

export default useOnSuccess;
