import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import { keyBy, orderBy } from "lodash";
import * as constants from "@constants";
import * as thunks from "ProfileModule/thunks";

import { initialState } from "ProfileModule/reducers";
import { DefaultBoard } from "types";

const selectProfileReducerRequestStatusesAndErrors = (state: AppStateType) =>
  state.profileReducer.requestStatusesAndErrors || {};

const selectProfileState = (state: AppStateType) =>
  state.profileReducer || initialState;

/* ------------------------------ Team Members ------------------------------ */

export const selectTeamMemberTableFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.teamMemberTable];

const selectTeamMembersState = createSelector(
  selectProfileState,
  (state) => state.team_members
);

const selectTeamMembersData = createSelector(
  selectTeamMembersState,
  selectProfileReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    isFetchingMember:
      requestStatusesAndErrors[thunks.fetchTeamMembers.typePrefix]
        ?.isRequesting,
    fetchingMemberError:
      requestStatusesAndErrors[thunks.fetchTeamMembers.typePrefix]?.error,

    isUpdatingMember:
      requestStatusesAndErrors[thunks.updateTeamMember.typePrefix]
        ?.isRequesting,
    updateMemberError:
      requestStatusesAndErrors[thunks.updateTeamMember.typePrefix]?.error,
  })
);

export const selectTeamMembers = createSelector(
  selectTeamMembersData,
  selectTeamMemberTableFilter,
  (
    {
      data: members,
      isUpdatingMember,
      updateMemberError,
      isFetchingMember,
      fetchingMemberError,
    },
    filter
  ) => {
    const filteredData = members.filter(
      (member) =>
        !member.email.includes("integrations.mosaicapp.com") &&
        !member.email.includes("maintenance.mosaicapp.com")
    );
    const sortedData = orderBy(
      filteredData,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      formattedData: sortedData,
      filter,
      isUpdatingMember,
      updateMemberError,
      isFetchingMember,
      fetchingMemberError,
    };
  }
);

export const selectTeamMembersHash = createSelector(
  [selectTeamMembersState],
  (members) => keyBy(members, "account_id")
);

/* ----------------------------- Team Selection ----------------------------- */

export const selectTeam = createSelector(
  selectProfileState,
  (state) => state.selectedTeam
);

export const selectCurrentTeamID = createSelector(
  [selectTeam],
  (selectedTeam) => selectedTeam.id
);

export const selectSelectedTargetService = createSelector(
  selectProfileState,
  (state) => state.selectedTargetService
);

export const selectTeamAPIToken = createSelector(
  selectTeam,
  selectProfileReducerRequestStatusesAndErrors,
  (selectedTeam, requestStatusesAndErrors) => ({
    data: selectedTeam.key,
    ...requestStatusesAndErrors[thunks?.fetchTeamApiToken.typePrefix],
  })
);

/* ------------------- Team Update Request Status & Error ------------------- */

export const selectUpdateTeamRequestStatusesAndErrors = createSelector(
  selectProfileReducerRequestStatusesAndErrors,
  (requestStatusesAndErrors) => ({
    ...requestStatusesAndErrors[thunks?.fetchTeamApiToken.typePrefix],
  })
);

/* -------------------------------- Provision ------------------------------- */
export const selectAgentToken = createSelector(
  selectProfileState,
  (state) => state.agentToken
);

export const selectAgentTokenData = createSelector(
  selectAgentToken,
  selectProfileReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.fetchAgentToken.typePrefix],
  })
);

export const selectProvisionIntegrationResponse = createSelector(
  selectProfileState,
  (state) => state.provisionIntegrationResponse
);

export const selectProvisionIntegrationResponseData = createSelector(
  selectProvisionIntegrationResponse,
  selectProfileReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    ...requestStatusesAndErrors[thunks.provisionIntegration.typePrefix],
  })
);

// export const selectCheckConnection = createSelector(
//   selectProfileState,
//   (state) => state.checkConnection
// );
// export const selectprovisionQbdResponseData = createSelector(
//   selectprovisionQbdResponse,
//   selectProfileReducerRequestStatusesAndErrors,
//   (data, requestStatusesAndErrors) => ({
//     data,
//     ...requestStatusesAndErrors[thunks.provisionQbd.typePrefix],
//   })
// );

export const selectBoards = createSelector(
  selectProfileState,
  (state) => state.default_board
);

export const selectDefaultBoardData = createSelector(
  selectBoards,
  selectProfileReducerRequestStatusesAndErrors,
  (defaultBoard, requestStatusesAndErrors) => ({
    data: defaultBoard as DefaultBoard,
    ...requestStatusesAndErrors[thunks.getDefaultBoard.typePrefix],
  })
);
