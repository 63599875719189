import { Sandbox } from "types";
import { ValueOf } from "type-fest";

/**
 * Builds sandbox baseURL from baseURL and realmId
 * by inserting realmId into the beginning of any API that is supported by sandboxes
 * Format: [protocol]//[realmid].api.[region].[env].mosaicapp.com
 * i.e: https://{realmId}.api.us-east-1.demo.mosaicapp.com - https://mosaic_proddump.api.us-east-1.demo.mosaicapp.com
 */
const buildSandboxURL = ({
  baseURL,
  realmId,
}: {
  baseURL: string;
  realmId: string;
}) => {
  const { protocol, host, pathname } = new URL(baseURL);

  // https://mosaicapp.slack.com/archives/C03G4RL6E3A/p1659470362967569?thread_ts=1659468577.776349&cid=C03G4RL6E3A
  // should replace [realmid] in the host with passed realmId

  return `${protocol}//${realmId}.${host}${pathname}`;
};

/** A class for managing api related things (i.e: when a sandbox is selected) */
class ApiClientManager {
  private railsSandbox: Sandbox;
  private readonly defaultRailsSandbox: Sandbox = {
    realm_id: "Demo", // Technically its not a sandbox, but an option to revert back to regular demo endpoint
    hosts: {},
    created_at: "",
    is_ready: true,
    tags: [],
  };

  constructor() {
    const railsSandbox = window.sessionStorage.getItem("sb-rails");
    this.railsSandbox = railsSandbox
      ? JSON.parse(railsSandbox)
      : this.getDefaultRailsSandbox();
  }

  reset() {
    this.removeRailsSandbox();
  }
  /**
   * baseUrl is used for building the url when a non-default sandbox is selected
   * defaultUrl is the default url that will be used if sandbox is not selected
   */
  buildUrl(
    sandbox: Sandbox,
    isUsingNonDefaultSandbox: boolean,
    baseURL: string,
    defaultUrl: string
  ) {
    const url = isUsingNonDefaultSandbox
      ? buildSandboxURL({
          realmId: sandbox.realm_id,
          baseURL,
        })
      : defaultUrl;

    return url;
  }

  /* ------------------------------ Rails Sandbox ----------------------------- */

  /**
   * Using sandbox means 1) Sandbox is enabled AND 2) Using a non default sandbox
   */
  getIsUsingNonDefaultRailsSandbox() {
    const sandbox = this.getRailsSandbox();
    const defaultRailsSandbox = this.getDefaultRailsSandbox();
    const notDefaultSandbox = sandbox.realm_id !== defaultRailsSandbox.realm_id;

    return this.getIsSandboxEnabled() && notDefaultSandbox;
  }

  getDefaultRailsSandbox() {
    return this.defaultRailsSandbox;
  }

  getRailsSandbox() {
    return this.railsSandbox;
  }

  getRailsSandboxRealmId() {
    return this.getRailsSandbox().realm_id;
  }

  // If we want to persist sandbox even closed browser, use localStorage
  setRailsSandbox(sandbox: Sandbox) {
    window.sessionStorage.setItem("sb-rails", JSON.stringify(sandbox));
  }

  removeRailsSandbox() {
    window.sessionStorage.removeItem("sb-rails");
  }

  getDefaultRailsServerUrl() {
    return process.env.REACT_APP_RAILS_API_URL as string;
  }

  getRailsServerUrl() {
    const sandbox = this.getRailsSandbox();

    return this.buildUrl(
      sandbox,
      this.getIsUsingNonDefaultRailsSandbox(),
      process.env.REACT_APP_SANDBOX_RAILS_API_URL as string,
      this.getDefaultRailsServerUrl()
    );
  }
  /* -------------------------------------------------------------------------- */

  getGalaxyServerUrl() {
    return process.env.REACT_APP_GALAXY_SERVER_API_URL;
  }

  getIntegrationServerUrl() {
    return process.env.REACT_APP_INTEGRATION_SERVER_API_URL;
  }

  getWebappUrl() {
    return process.env.REACT_APP_WEB_APP_URL;
  }

  // Sandbox is enabled only on Demo
  getIsSandboxEnabled() {
    return process.env.REACT_APP_UI_ENV === "demo";
  }

}

export default new ApiClientManager();
