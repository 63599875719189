import { current } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import mergeWith from "lodash/mergeWith";
import isArray from "lodash/isArray";

import { integrationHealthIsoStateIds } from "@constants";
import { IntegrationHealthIsoStateModifierParams } from "IntegrationModule/types";
import { IntegrationHealth } from "types";
import { serializeId } from "utils";
import * as thunkActions from "thunk";

const noop = () => {};

const mergeObjects = (
  obj1: Record<any, any> | null,
  obj2: Record<any, any> | null
) => {
  return mergeWith(obj1, obj2, (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  });
};

/* --------------------------- Integration Health --------------------------- */

const groupByTargetServiceThenKeyedById = (data: IntegrationHealth[]) => {
  const groupedByTargetService = groupBy(data, "serviceName");

  const integrationHealthGroupedByServiceNameThenKeyedByIdData: {
    [serviceName: string]: {
      [targetServiceId: number]: IntegrationHealth;
    };
  } = {};

  Object.keys(groupedByTargetService).forEach((serviceName) => {
    integrationHealthGroupedByServiceNameThenKeyedByIdData[serviceName] = keyBy(
      groupedByTargetService[serviceName],
      "targetServiceId"
    );
  });

  return integrationHealthGroupedByServiceNameThenKeyedByIdData;
};

const stub: IntegrationHealth[] = [
  {
    id: 1,
    date: "2022-05-18T00:00:00.000Z",
    errors: {},
    requestCount: 1534,
    serviceName: "jira-cloud",
    successCount: 1534,
    targetServiceId: 14,
  },
  {
    id: 1,
    date: "2022-05-18T00:00:00.000Z",
    errors: {},
    requestCount: 1534,
    serviceName: "jira-cloud",
    successCount: 1534,
    targetServiceId: 14,
  },
  {
    id: 1,
    date: "2022-05-18T00:00:00.000Z",
    errors: {},
    requestCount: 1534,
    serviceName: "jira-cloud",
    successCount: 1534,
    targetServiceId: 14,
  },
  {
    id: 1,
    date: "2022-05-18T00:00:00.000Z",
    errors: {},
    requestCount: 1534,
    serviceName: "jira-cloud",
    successCount: 1534,
    targetServiceId: 14,
  },
];

const groupByTargetServiceThenGroupById = (data: IntegrationHealth[]) => {
  const groupedByTargetService = groupBy(data, "serviceName");

  const integrationHealthGroupedByServiceNameThenGroupByIdData: {
    [serviceName: string]: {
      [targetServiceId: number]: IntegrationHealth[];
    };
  } = {};
  Object.keys(groupedByTargetService).forEach((serviceName) => {
    integrationHealthGroupedByServiceNameThenGroupByIdData[
      serviceName
    ] = groupBy(groupedByTargetService[serviceName], "targetServiceId");
  });

  return integrationHealthGroupedByServiceNameThenGroupByIdData;
};

const handleGroupIntegrationHealthByTargetService = ({
  state,
  action,
  payload,
  isoStateId,
}: IntegrationHealthIsoStateModifierParams) => {
  state.requestStatusesAndErrors[
    serializeId([thunkActions.fetchIntegrationHealth.typePrefix, isoStateId])
  ] = {
    isRequesting: false,
    error: {},
  };
  const { meta } = action;
  const { arg } = meta;

  state.integration_health[isoStateId] = {
    ...state.integration_health[isoStateId],
    ...groupBy(payload, "serviceName"),
  };
};

const handleGroupIntegrationHealthByTargetServiceThenKeyedByTargetServiceId = ({
  state,
  action,
  payload,
  isoStateId,
}: IntegrationHealthIsoStateModifierParams) => {
  state.requestStatusesAndErrors[
    serializeId([thunkActions.fetchIntegrationHealth.typePrefix, isoStateId])
  ] = {
    isRequesting: false,
    error: {},
  };
  state.integration_health[isoStateId] = {
    ...state.integration_health[isoStateId],
    ...(payload && groupByTargetServiceThenKeyedById(payload)),
  };
};

const handleGroupIntegrationHealthByTargetServiceThenGroupByTargetServiceId = ({
  state,
  action,
  payload,
  isoStateId,
}: IntegrationHealthIsoStateModifierParams) => {
  state.requestStatusesAndErrors[
    serializeId([thunkActions.fetchIntegrationHealth.typePrefix, isoStateId])
  ] = {
    isRequesting: false,
    error: {},
  };
  state.integration_health[isoStateId] = mergeObjects(
    state.integration_health[isoStateId],
    payload && groupByTargetServiceThenGroupById(payload)
  );
};

const triggerFetchingAndResetIsoState = ({
  state,
  action,
  isoStateId,
}: IntegrationHealthIsoStateModifierParams) => {
  state.requestStatusesAndErrors[
    serializeId([thunkActions.fetchIntegrationHealth.typePrefix, isoStateId])
  ] = {
    isRequesting: true,
    error: {},
  };
  state.integration_health[isoStateId] = {};
};

const triggerFetchingAndConditionalResetIsoStateIfInitial = ({
  state,
  action,
  isoStateId,
}: IntegrationHealthIsoStateModifierParams) => {
  state.requestStatusesAndErrors[
    serializeId([thunkActions.fetchIntegrationHealth.typePrefix, isoStateId])
  ] = {
    isRequesting: true,
    error: {},
  };
  const { meta } = action;
  const { arg } = meta;

  if (arg.isInitialFetch) {
    state.integration_health[isoStateId] = {};
  }
};

// if no targetServiceId is provided, we group them all together and total up the ones with the same serviceName
export const integrationHealthIsoStateModifiers = {
  /**
   * start date = today
     end date = No
     group by target service -> { github: [{}], ... }
     Fetch by target service
     ** Sums up all request and success using sumRequestAndSuccessIntegrationHealthCountsByTargetService() ** 
     (Note there is no targetServiceId so cannot show for individual targetServiceId)
     successRequestRatio = success / request
 
     getIntegrationHealthAlertDisplay() -> Check, the percentage to alert may change
   */
  [integrationHealthIsoStateIds.IntegrationCard]: {
    handlePending: noop,
    handleFulfilled: handleGroupIntegrationHealthByTargetService,
  },

  /**
   * Clear state when trigger new fetch on change
   * start date = today - 1, - 7, -30 depends on the date
     end date = today
     group by target service -> { github: [{}], ... }
     Fetch by target service
     ** Sums up all request and success using sumRequestAndSuccessIntegrationHealthCountsByTargetService() ** 
     (Note there is no targetServiceId so cannot show for individual targetServiceId)
     successRequestRatio = success / request
     getIntegrationHealthAlertDisplay() -> Check, the percentage to alert may change
   */
  [integrationHealthIsoStateIds.TotalCountTable]: {
    handlePending: triggerFetchingAndResetIsoState,
    handleFulfilled: handleGroupIntegrationHealthByTargetService, // handleGroupIntegrationHealthByTargetServiceThenKeyedByTargetServiceId,
  },

  /**
   * Clear state when trigger new fetch on change
   * start date = today (Ask -> Should this depend on the  date picker)
     end date = today
     group by target service and key by id -> { github: { 1: { }, 2: {} } }
     Fetch by target service IDS
     
     getIntegrationHealthAlertDisplay() -> Check, the percentage to alert may change
   */
  [integrationHealthIsoStateIds.SyncRatioTable]: {
    handlePending: triggerFetchingAndResetIsoState,
    handleFulfilled: handleGroupIntegrationHealthByTargetServiceThenKeyedByTargetServiceId,
  },

  /**
   * start date = today
     end date = today
     group by target service and key by id -> { github: { 1: { }, 2: {} } }
     Fetch by target service IDS
     
     getIntegrationHealthAlertDisplay() -> Check, the percentage to alert may change
   */
  [integrationHealthIsoStateIds.TeamProfileIntegrationList]: {
    handlePending: noop, // Cannot reset state because multiple fetches are going out per integration
    handleFulfilled: handleGroupIntegrationHealthByTargetServiceThenKeyedByTargetServiceId,
  },

  /**
   * - Integration Health Table on Integration Details page (not used)
   *  Paginate by date
   *  startDate = today - 7, -14, -21, etc... 
   *  endDate = startDate - 7
   *  Fetch by targetServiceIds (just one)
   *  groupby target service and group by id -> { github: { 1: [], 2: [] } }
   * 
   *  - Integration Health Table on Integration Health page
   *  No pagination
   *  startDate = today - 30 (Unsure because not sure how to fetch all 90 entries)
   *  endDate = today
   *  Fetch by targetServiceIds (just one)
   *  groupby target service and group by id -> { github: { 1: [], 2: [] } }
   * 
     
     getIntegrationHealthAlertDisplay() -> Check, the percentage to alert may change
   */
  [integrationHealthIsoStateIds.IntegrationHealthTable]: {
    handlePending: triggerFetchingAndConditionalResetIsoStateIfInitial,
    handleFulfilled: handleGroupIntegrationHealthByTargetServiceThenGroupByTargetServiceId,
  },
  [integrationHealthIsoStateIds.IntegrationHealthCalendar]: {
    handlePending: triggerFetchingAndConditionalResetIsoStateIfInitial,
    handleFulfilled: handleGroupIntegrationHealthByTargetServiceThenGroupByTargetServiceId,
  },
};

/* -------------------------------------------------------------------------- */
