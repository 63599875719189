import { Sandbox } from "types";
import ApiClientManager from "core/apiClient";

/**
 * Sandbox is destroyable when
 * - is_ready = true, (implicitly hasError = false)
 * - is_ready = false, hasError = true (BE flag - still need this)
 */
export const checkIsSandboxDestroyable = ({
  isReady,
}: {
  isReady: boolean;
}) => {
  // TODO - Add this when there is an error flag
  // || (!is_ready && hasError = true)
  return isReady;
};

export const checkIsCurrentlyUsingThisRailsSandbox = (
  realmId: Sandbox["realm_id"]
) => {
  const currentlyUsedRailsSandboxRealmId = ApiClientManager.getRailsSandboxRealmId();

  return realmId === currentlyUsedRailsSandboxRealmId;
};
