import { AppStateType } from "reducers";
import { createSelector } from "reselect";
import { keyBy, orderBy } from "lodash";
import * as constants from "@constants";
import { initialState } from "SandboxModule/reducers";
import * as SandboxModuleThunks from "SandboxModule/thunks";

const emptyObj = {};

const selectSandboxReducerState = (state: AppStateType) =>
  state.sandboxReducer || initialState;

const selectSandboxReducerRequestStatusesAndErrors = createSelector(
  selectSandboxReducerState,
  (state) => state.requestStatusesAndErrors
);

export const selectCreateSandboxData = createSelector(
  selectSandboxReducerRequestStatusesAndErrors,
  (requestStatusesAndErrors) => ({
    ...requestStatusesAndErrors[SandboxModuleThunks.createSandbox.typePrefix],
  })
);

/* -------------------------------------------------------------------------- */

const selectRailsSandboxes = createSelector(
  selectSandboxReducerState,
  (state) => state.railsSandboxes
);

const selectRailsSandboxesTableFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.railsSandboxesTable];

export const selectRailsSandboxesHash = createSelector(
  selectRailsSandboxes,
  (sandboxes) => keyBy(sandboxes, "realm_id")
);

export const selectSelectedRailsSandbox = createSelector(
  selectSandboxReducerState,
  (state) => state.selectedRailsSandbox
);

export const selectRailsSandboxesData = createSelector(
  selectRailsSandboxes,
  selectRailsSandboxesTableFilter,
  selectRailsSandboxesHash,
  selectSelectedRailsSandbox,
  selectSandboxReducerRequestStatusesAndErrors,
  (
    sandboxes,
    filter,
    sandboxHash,
    selectedRailsSandbox,
    requestStatusesAndErrors
  ) => {
    const { limit, offset } = filter;
    const formattedSandboxes = orderBy(
      sandboxes,
      filter.orderBy,
      filter.orderDirection
    );

    return {
      data: formattedSandboxes,
      sandboxHash,
      selectedRailsSandbox,
      allRealmIds: sandboxes.map((sb) => sb.realm_id),
      filter,
      ...requestStatusesAndErrors[
        SandboxModuleThunks.fetchSandboxes.typePrefix
      ],
    };
  }
);

/* -------------------------------------------------------------------------- */
const selectAvailableDumpFiles = createSelector(
  selectSandboxReducerState,
  (state) => state.dumpFiles
);

export const selectAvailableDumpFilesData = createSelector(
  selectAvailableDumpFiles,
  selectSandboxReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data: data.map((file) => ({ value: file, label: file })),
    ...requestStatusesAndErrors[
      SandboxModuleThunks.fetchAvailableDumpFiles.typePrefix
    ],
  })
);
