import { Draft, createReducer } from "@reduxjs/toolkit";
import * as thunkActions from "thunk";
import * as actions from "actionCreators";
import {} from "types";
import * as StatisticsTypes from "StatisticsModule/types";

type InitialStateType = {
  requestStatusesAndErrors: {
    [x: string]: {
      isRequesting: any;
      error: any;
      isSuccess?: boolean;
    };
  };
};

export const initialState: InitialStateType = {
  requestStatusesAndErrors: {},
};

export default createReducer(initialState, (builder) => {
  /* ---------------------------- Non Async Request --------------------------- */
  //   builder.addCase(actions.setSelectedTeam, (state, { payload }) => {
  //     state.selectedTeam = payload;
  //   });

  //   builder.addCase(actions.setTargetService, (state, { payload }) => {
  //     state.selectedTargetService = payload;
  //   });

  //   builder.addCase(actions.updateProfileState, (state, { payload }) => {
  //     const nextState = { ...state };
  //     Object.keys(payload).forEach((reduxKey) => {
  //       nextState[reduxKey as keyof typeof nextState] = payload[reduxKey];
  //     });
  //     return nextState;
  //   });
});
