import { useSelector } from "react-redux";
import { useMemo } from "react";
import Item from "components/SidebarItem";
import styled from "styled-components";
import { makeGetSelectActiveIntegrationCountsAndIntegrationHealthByIsoState } from "selectors";
import {
  AVAILABLE_INTEGRATION_SERVICES,
  integrationHealthIsoStateIds,
  intervalsHash,
  integrationsHash,
  defaultCurrentService,
  defaultSelectedInterval,
} from "@constants";
import { renderIntegrationHealthByRequestAndSuccessCountPercent } from "utils";
import useGeneralIntegrationPageContext from "hooks/useGeneralIntegrationPageContext";
import useAppNavigation from "hooks/useAppNavigation";
import useServiceMetricsTable from "hooks/useServiceMetricsTable";
import { AppStateType } from "../redux/reducers";

const IntegrationSidebar = ({ integrationId }: { integrationId: string }) => {
  const {
    intervalToUse,
    selectedIntegration,
  } = useGeneralIntegrationPageContext();
  const { navigateToGeneralIntegrationPage } = useAppNavigation();
  const { isIntegrationServiceMetricsTableLoading } = useServiceMetricsTable({
    currentService: integrationId,
    selectedInterval: intervalToUse.id,
  });

  const selectActiveIntegrationCountsAndIntegrationHealthByIsoState = useMemo(
    makeGetSelectActiveIntegrationCountsAndIntegrationHealthByIsoState,
    []
  );

  const activeIntegrationHealthHash = useSelector((state) =>
    selectActiveIntegrationCountsAndIntegrationHealthByIsoState(
      state as AppStateType,
      {
        isoStateId: integrationHealthIsoStateIds.IntegrationCard,
      }
    )
  );
  const integrationHealth =
    activeIntegrationHealthHash[integrationId]?.integrationHealth;

  const healthIcon = renderIntegrationHealthByRequestAndSuccessCountPercent({
    integrationHealth,
    className:
      "icon-only inline-block remove-icon-padding remove-message-padding",
    style: { position: "relative", top: 6, left: 6 },
    linkIconClassName: "sidebar",
  });

  const currentIntegration = activeIntegrationHealthHash[integrationId];
  const integrationLabel = currentIntegration?.label;
  const integrationActiveCount = currentIntegration?.activeCount;

  return (
    <Item
      id={integrationId}
      onClick={() => {
        navigateToGeneralIntegrationPage(integrationId, intervalToUse.id);
      }}
      disabled={isIntegrationServiceMetricsTableLoading}
      active={integrationId === selectedIntegration.id}
    >
      <>
        {healthIcon} {integrationLabel} | {integrationActiveCount}
      </>
    </Item>
  );
};

export default IntegrationSidebar;
