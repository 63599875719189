import useUrl from "hooks/useUrl";
import {
  AVAILABLE_INTEGRATION_SERVICES,
  intervalsHash,
  defaultCurrentService,
  defaultSelectedInterval,
  integrationsHash,
} from "@constants";

const useGeneralIntegrationPageContext = () => {
  const { queryInterval, queryTargetService } = useUrl();
  const intervalToUse = intervalsHash[queryInterval] || defaultSelectedInterval;

  const isValidTargetService =
    queryTargetService &&
    AVAILABLE_INTEGRATION_SERVICES.has(queryTargetService);

  const targetService = isValidTargetService
    ? queryTargetService
    : defaultCurrentService;

  const selectedIntegration = integrationsHash[targetService];

  return { intervalToUse, targetService, selectedIntegration };
};

export default useGeneralIntegrationPageContext;
